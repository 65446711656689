import { createStore } from 'vuex'

import axios from "axios"
import { CONFIG } from "@/config.js"
import router from "@/router.js"

const api = axios.create({
  baseURL: CONFIG.api.baseUrl,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken_new"
});

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && [401, 403].some(code => code == error.response.status)) {
      router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
)

// Create a new store instance.
const store = createStore({
  state() {
    return {
      api: api,
      user: {},
      permissions: [],
      formPart: null
    }
  },
  getters: {
    api: function (state) {
      return state.api;
    },
    isLoggedIn: function (state) {
      return Object.keys(state.user).length != 0;
    },
    user: function (state) {
      return state.user;
    },
    language: function (state) {
      return state.language;
    },
    languages: function (state) {
      return state.languages;
    },
    hasPerm: (state) => (perm) => {
      return state.permissions.includes(perm);
    },
    baseApiUrl: function (state) {
      return CONFIG.api.baseUrl;
    },
    formPart: function (state) {
      return state.formPart;
    }
  },
  mutations: {
    getUserProfile(state) {
      state.api.get("/auth/user").then(response => {
        const data = response.data.user;
        store.commit("saveUserProfile", data);
      });
    },
    saveUserProfile(state, data) {
      state.permissions = [...data.permissions];
      delete data.permissions;
      state.user = data;
    },
    changeFormPart(state, formPart) {
      state.formPart = formPart;
    }
  },
});


export default store;