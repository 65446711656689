<template>
    <div class="row justify-content-center align-items-center vh-100 w-100 m-0">
        <div class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 form-box">
                <div class="d-flex flex-wrap justify-content-between">
                    <h2>{{ $t("continueForm.title") }}</h2>
                    <ChangeLang class="mb-2"/>
                </div>
                <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                    {{ $t("continueForm.invalid") }}
                </div>
                <label for="pin" class="form-label my-2">{{ $t("continueForm.pin") }}</label>
                <input type="password"
                id="pin"
                class="form-control"
                ref="pin"
                v-model="pin"
                placeholder="PIN"
                @keyup.enter="verifyPin()"
                >
                <button type="submit"
                class="mt-4 p-2 btn btn-primary w-100 form-submit"
                @click="verifyPin()"
                >
                    {{ $t("continueForm.button") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ChangeLang from "@/components/ChangeLang.vue"

export default {
    components: {
        ChangeLang
    },
    data() {
        return {
            username: null,
            invalid: false,
            slug: "",
            pin: ""
        }
    },
    methods: {
        redirectToForm(attempt, maxAttempts, length) {
            setTimeout(function () {
                if (this.$store.getters.isLoggedIn) {
                this.$router.push({ name: 'form', params: { uuid: this.slug } });
                }
                else {
                if (attempt >= maxAttempts) {
                    alert(this.$t("error.longLogin"))
                }
                else {
                    this.redirectToForm(attempt + 1, maxAttempts, length);
                }
                }
            }.bind(this), length);
        },
        generatePin: function () {
            this.$store.getters.api.get("/order-continue/" + this.slug).then(
                (response) => {
                    if (response.data.success && response.data.success === "User succesfully verified.") {
                        this.$store.commit("getUserProfile");
                        this.redirectToForm(0, 50, 100);
                    }
                }
            )
        },
        verifyPin: function () {
            this.$store.getters.api.post("/order-continue/" + this.slug,
                { "pin": this.pin }
            ).then(
                (_response) => {
                    this.$store.commit("getUserProfile");
                    this.redirectToForm(0, 50, 100);
                }
            ).catch((_error) => {
                this.invalid = true;
            });
        }
    },
    created: function () {
        if (this.$route.params.uuid && this.$route.params.uuid !== "") {
            this.slug = this.$route.params.uuid;
            this.generatePin();
        }
    }
}
</script>

<style lang="scss">
</style>