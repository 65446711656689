<template>
  <div>
    <template v-if="editable">
      <template v-if="sections.length >= 2 && changeAbleParts">
        <div class="text-center">
          <h3>{{ $t("form.floor") }}</h3>
          <template v-for="i in sections.length" v-key="i">
              <button type="button"
                class="btn btn-primary text-white float-left me-2 floor_btn"
                @click="changeSections(i - 1)"
                :class="{
                  'wrong_floor': !rightFilled(i),
                  'good_floor': rightFilled(i),
                  'active_floor': i == selectedSection + listRemoveZero,
                }">
                <template v-if="listStart - i >= 0">{{ i - listStart - (listRemoveZero) }}</template>
                <template v-else-if="listStart + listRemoveZero - i <= 0">{{ i - listStart }}</template>
              </button>
          </template>
        </div>
      </template>
      <template v-for="(section, index) in sections" :key="index">
        <div class="mb-3 mt-4" v-if="selectedSection == index || !changeAbleParts">
          <div class="p-2">
            <div v-if="!lockSections" class="d-flex justify-content-between mb-2">
              <div class="input-group input-group-lg me-2">
                <input class="form-control" type="text" :placeholder="$t('utils.name')" v-model="section.name" />
              </div>
              <button class="btn btn-outline-danger" @click="sections.splice(index, 1)">
                <icon icon="Trash" />
              </button>
            </div>
            <div v-else class="px-2 d-flex justify-content-between">
              <h3 class="my-2">{{ section.name }}</h3>
              <SectionInfo />
            </div>
            <template v-if="section.heating !== undefined">
              <label class="form-label">{{ $t("form.heatingDescription") }}:</label>
              <div class="form-check mb-2">
                <input class="form-check-input" type="radio" :name="`heating${index}`" :id="`heating${index}1`" v-model="section.heating" value="1">
                <label class="form-check-label ms-2" :for="`heating${index}1`">
                  {{ $t("form.heating1") }}
                </label>
              </div>
              <div class="form-check mb-2">
                <input class="form-check-input" type="radio" :name="`heating${index}`" :id="`heating${index}2`" v-model="section.heating" value="2">
                <label class="form-check-label ms-2" :for="`heating${index}2`">
                  {{ $t("form.heating2") }}
                </label>
              </div>
              <div class="form-check mb-3">
                <input class="form-check-input" type="radio" :name="`heating${index}`" :id="`heating${index}3`" v-model="section.heating" value="3">
                <label class="form-check-label ms-2" :for="`heating${index}3`">
                  {{ $t("form.heating3") }}
                </label>
              </div>
              <div v-if="section.heating == '3'">
                <p>{{ $t("heating.heading") }}</p>
                <textarea class="form-control mb-3" v-model="section.heatingNote" required />
              </div>
            </template>
            <template v-if="!hideFiles && section.fileCanBeUploaded !== undefined">
                <div class="form-check mb-2 mt-3">
                  <input type="checkbox" class="form-check-input" v-model="section.fileCanBeUploaded"
                    :true-value="false"
                    :false-value="true"/>
                  <label class="form-label ms-2">{{ $t("form.fileCanBeUploaded") }}</label>
                </div>
            </template>
            <template v-if="!hideFiles && (section.fileCanBeUploaded === undefined || section.fileCanBeUploaded)">
              <Files :editable="editable" :files="section.files" @updatePreview="updatePreview" @saveFileChanges="saveFileChanges" :formPart="formPart"/>
            </template>
            <template v-if="!hideCompositions">
              <label class="form-label">{{ $t("form.sameCompositionAsFile") }}</label>
              <div class="form-check mb-2">
                <input type="radio" class="form-check-input"  v-model="section.sameCompositionAsFile" :value="true"/>
                <label class="form-check-label ms-2">{{ $t("utils.yes") }}</label>
              </div>
              <div class="form-check mb-3">
                <input type="radio" class="form-check-input"  v-model="section.sameCompositionAsFile" :value="false"/>
                <label class="form-check-label ms-2">{{ $t("utils.no") }}</label>
              </div>
              <template v-if="!section.sameCompositionAsFile">
                <label class="form-label">{{ $t("form.sameCompositionOnWall") }}</label>
                <div class="form-check mb-2">
                  <input type="radio" class="form-check-input"  v-model="section.sameCompositionOnWall" :value="true"/>
                  <label class="form-check-label ms-2">{{ $t("utils.yes") }}</label>
                </div>
                <div class="form-check mb-3">
                  <input type="radio" class="form-check-input"  v-model="section.sameCompositionOnWall" :value="false"/>
                  <label class="form-check-label ms-2">{{ $t("utils.no") }}</label>
                </div>
                <h4 class="m-2 mb-3">{{ $t("form.sectionCompositions") }}:</h4>
                <Composition :editable="editable" :compositions="section.compositions" :materialOptions="materialOptions" :canAddComposition="!section.sameCompositionOnWall"/>
              </template>
            </template>
            <template v-if="!hideDescription">
              <label class="form-label">{{ $t(sectionDescription) }}:</label>
              <textarea class="form-control mb-3" v-model="section.description" />
            </template>
          </div>
        </div>
      </template>
      <ul v-if="!lockSections" class="nav mb-3">
        <li class="nav-item">
          <button class="nav-link py-0 nav-size-3" @click="addSection">+&nbsp;{{ $t("form.sectionAdd") }}</button>
        </li>
      </ul>
    </template>
    <template v-else>
      <template v-for="(section, index) in sections" :key="index">
        <div class="border-dark mb-3">
          <div class="px-4">
            <h3 class="my-2">{{ section.name }}</h3>
          </div>
          <div class="p-1">
            <template v-if="section.heating !== undefined">
              <label class="form-label m-2">{{ $t("form.heatingDescription") }}:</label>
              <div v-if="section.heating === '1'" class="m-2">{{ $t("form.heating1") }}</div>
              <div v-else-if="section.heating === '2'" class="m-2">{{ $t("form.heating2") }}</div>
              <div v-else-if="section.heating === '3'" class="m-2">{{ $t("form.heating3") }}</div>
              <p v-if="section.heating === '3'" class="m-2">{{ section.heatingNote }}</p>
            </template>
            <template v-if="!hideFiles && (section.fileCanBeUploaded === undefined || section.fileCanBeUploaded)">
              <Files :editable="editable" :files="section.files" @updatePreview="updatePreview" />
            </template>
            <template v-else-if="(section.fileCanBeUploaded !== undefined && !section.fileCanBeUploaded)">
              <p>{{ $t("form.fileCanBeUploaded") }}  {{ $t("utils.yes") }}</p>
            </template>
            <template v-if="!hideCompositions">
              <template v-if="!section.sameCompositionAsFile">
                <h4 class="m-2 mb-3">{{ $t("form.sectionCompositions") }}:</h4>
                <Composition :editable="editable" :compositions="section.compositions" :materialOptions="materialOptions" :materialDefaultOptions="2" :canAddComposition="!section.sameCompositionOnWall"/>
              </template>
              <template v-else>
                  {{ $t("form.sameCompositionAsFileDescription") }}
              </template>
            </template>
            <template v-if="!hideDescription">
              <p class="p-2">{{ section.description }}</p>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Composition from "@/components/Composition.vue"
import Files from "@/components/Files.vue"
import Icon from "@/components/Icon.vue"
import SectionInfo from "@/components/SectionInfo.vue"

export default {
  components: {
    Composition,
    Files,
    Icon,
    SectionInfo
  },
  emits: ['updatePreview', 'saveFileChanges'],
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    lockSections: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFiles: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCompositions: {
      type: Boolean,
      required: false,
      default: false
    },
    sections: {
      type: Array,
      required: true
    },
    materialOptions: {
      type: Array,
      required: true
    },
    hideDescription: {
      type: Boolean,
      required: false,
      default: false
    },
    sectionDescription: {
      type: String,
      required: false,
      default: "form.sectionDescription"
    },
    changeAbleParts: {
      type: Boolean,
      required: false,
      default: false
    },
    listStart: {
      type: Number,
      required: false,
      default: 0
    },
    listRemoveZero: {
      type: Boolean,
      required: false,
      default: true
    },

  },
  data() {
    return {
      selectedSection: 0
    }
  },
  methods: {
    rightFilled(section) {
      if (!this.sections || !this.sections[section - 1]) {
        return false;
      }
      var files = this.sections[section - 1].files;
      if (files && files.length === 0) {
          return false;
      }
      return true;
    },
    changeSections(index) {
      this.selectedSection = index;
    },
    addSection() {
      this.sections.push({
        name: "",
        description: "",
        files: [],
        compositions: [
          {
            name: "",
            materials: [
              {
                type_id: "",
                type: "",
                thickness: 0
              }
            ],
          }
        ]
      });
    },
    updatePreview(fileObject, fileUrl) {
      this.$emit('updatePreview', fileObject, fileUrl);
    },
    saveFileChanges() {
      this.$emit('saveFileChanges');
    }
  }
}
</script>

<style scoped lang="scss">
.floor_btn {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 20px;
  border: none;
}

.good_floor {
  background-color: #43B02A;
}

.wrong_floor {
  background-color: rgb(176, 42, 42);
}

.active_floor {
  background-color: #1250BE !important;
}

</style>
