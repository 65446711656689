<template>
    <div>
        <div v-if="valid === null">
            <h1>{{ $t("utils.loading") }}</h1>
        </div>
        <div v-else-if="valid">
            <form class="container-lg" ref="form">
                <h1>{{ $t("staffCreate.heading") }}</h1>
                <h2>{{ $t("staffCreate.personal") }}</h2>
                <EditableRow :header="$t('staffCreate.firstName')" :editable="true" :isRequired="true" :body="userData.firstName"
                    @body="(newValue) => userData.firstName = newValue" />
                <EditableRow :header="$t('staffCreate.lastName')" :editable="true" :isRequired="true" :body="userData.lastName"
                    @body="(newValue) => userData.lastName = newValue" />
                <EditableRow :header="$t('staffCreate.email')" :editable="true" :isRequired="true" :body="userData.email"
                    :inputType="'email'" @body="(newValue) => userData.email = newValue" />
                <EditableRow :header="$t('staffCreate.phone')" :editable="true" :isRequired="true" :body="userData.phone"
                    :inputType="'phone'" @body="(newValue) => userData.phone = newValue" />
                <EditableRow :header="$t('staffCreate.maximum')" :editable="true" :isRequired="true" :body="userData.maximum"
                    :inputType="'number'" :min="0" @body="(newValue) => userData.maximum = newValue" />
                <EditableRow :header="$t('staffCreate.password')" :editable="true" :isRequired="true" :body="userData.password"
                    :inputType="'password'" @body="(newValue) => userData.password = newValue" />
                <EditableRow :header="$t('staffCreate.passwordAgain')" :editable="true" :isRequired="true" :body="userData.passwordAgain"
                    :inputType="'password'" @body="(newValue) => userData.passwordAgain = newValue" />
                <h2>{{ $t("staffCreate.billing") }}</h2>
                <EditableRow :header="$t('staffCreate.organizationName')" :editable="true" :isRequired="true"
                    :body="userData.organizationName" @body="(newValue) => userData.organizationName = newValue" />
                <EditableRow :header="$t('staffCreate.organizationId')" :editable="true" :isRequired="true"
                    :body="userData.organizationId" @body="(newValue) => userData.organizationId = newValue" />
                <EditableRow :header="$t('staffCreate.taxId')" :editable="true" :isRequired="false" :body="userData.taxId"
                    @body="(newValue) => userData.taxId = newValue" />
                <EditableRow :header="$t('staffCreate.address')" :editable="true" :isRequired="true" :body="userData.address"
                    @body="(newValue) => userData.address = newValue" />
                <EditableRow :header="$t('staffCreate.town')" :editable="true" :isRequired="true" :body="userData.town"
                    @body="(newValue) => userData.town = newValue" />
                <EditableRow :header="$t('staffCreate.zip')" :editable="true" :isRequired="true" :body="userData.zip"
                    @body="(newValue) => userData.zip = newValue" />
                <button type="submit" class="btn btn-lg btn-primary mx-auto mt-3 mb-3 text-white">
                    {{ $t("staffCreate.create") }}</button>
            </form>
        </div>
        <div v-else>
            <h1>{{ $t("staffCreate.unvalid") }}</h1>
        </div>
    </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"

export default {
    components: {
        EditableRow
    },
    data() {
        return {
            valid: null,
            userData: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                maximum: 0,
                organizationName: null,
                organizationId: null,
                taxId: null,
                address: null,
                town: null,
                zip: null,
                password: null,
                passwordAgain: null,
                uuid: this.$route.params.uuid
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        saveData: function () {
            if (!this.valid) {
                return
            }
            if (this.userData.password && this.userData.passwordAgain && this.userData.password !== this.userData.passwordAgain) {
                alert(this.$t("user.newPasswordsNotMatch"));
                return
            }
            this.$store.getters.api.post("/user/create", this.userData).then((response) => {
                this.$router.push({"name": "login"})
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.error_code) {
                    alert(this.$t("error." + error.response.data.error_code ));
                }
                else {
                    alert(this.$t("error.undefined" + ": " + error));
                }
            });
        },
        loadData: function () {
            this.$store.getters.api.get("/validate/" + this.$route.params.uuid + "/staff").then((response) => {
                this.valid = response.data.valid;
                setTimeout(() => {
                    if (this.$refs.form) {
                        this.$refs.form.addEventListener("submit", (event) => {
                            event.preventDefault();
                            if (this.validateData() === false){
                                return false;
                            }
                            this.saveData();
                            return false;
                        });
                    }
                }, "100");
            })
        },
        validateData() {
            if (this.$refs.form.checkValidity() === false){
                this.$refs.form.reportValidity();
                return false;
            }
            return true;
        },
    }
}
</script>

<style lang="scss"></style>