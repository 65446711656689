<template>
    <button type="button" :class="styles" data-bs-toggle="modal" :data-bs-target="`#${idName}`">
        <icon icon="Info"/>
    </button>
    <Popup :header="popupHeader" :editable="false" :id="idName" v-if="popup">
        <template v-slot:body>
        <slot name="popup"></slot>
        </template>
    </Popup>
</template>

<script>
import Popup from "@/components/Popup.vue";
import { v4 as uuidv4 } from 'uuid';

export default {
    components: {
        Popup
    },
    data() {
      return {
        idName: `info-${uuidv4()}`
      };
    },
    props: {
        popup: {type: Boolean, required: false, default: true},
        popupHeader: {type: String, required: false},
        styles: {type: String, required: false},
    },
}
</script>

<style lang="scss">
.btn_color_white:hover {
  color: white !important;
}
</style>