<template>
  <nav class="navbar navbar-expand-xl fixed-top bg-secondary custom-navbar">
    <router-link class="navbar-brand px-4 text-nowrap" to="/orders">
      <img id="logo" src="/RayHome-logo.png" alt="RayHome Energy" />
    </router-link>
    <button class="navbar-toggler ms-auto me-4"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse bg-secondary" id="navbarNavAltMarkup">
      <div class="navbar-nav ms-auto">
        <router-link v-if="$store.getters.hasPerm('add_user')" class="nav-link px-4 text-nowrap" to="/staff" @click="collapseNavbar">
          {{ $t("navbar.staff") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/orders" @click="collapseNavbar">
          {{ $t("navbar.orders") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/invoicing" @click="collapseNavbar">
          {{ $t("navbar.invoicing") }}
        </router-link>
        <router-link v-if="$store.getters.hasPerm('view_affiliatelink')" class="nav-link px-4 text-nowrap" to="/links" @click="collapseNavbar">
          {{ $t("navbar.links") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/profile" @click="collapseNavbar">
          {{ $t("navbar.myProfile") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/logout" @click="collapseNavbar">
          {{ $t("navbar.logout") }}
        </router-link>
        <ChangeLang class="mx-4 my-2 my-md-1" />
      </div>
    </div>
  </nav>
</template>


<script>
import ChangeLang from "@/components/ChangeLang.vue"
import bootstrap from 'bootstrap/dist/js/bootstrap.js'

export default {
  components: {
    ChangeLang
  },
  data() {
    return {
    }
  },
  methods: {
    collapseNavbar() {
      const navbarMenu = document.getElementById('navbarNavAltMarkup');

      // Utilize Bootstrap's JavaScript API to toggle the collapse state
      const bsCollapse = new bootstrap.Collapse(navbarMenu, {
        toggle: true
      });

      // If it's showing, hide it
      if (navbarMenu.classList.contains('show')) {
        bsCollapse.hide();
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
.navbar-brand {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.nav-link {
  font-weight: 500;
  font-size: 1.4rem;
}

#logo {
  max-height: 60px;
}

.custom-navbar.fixed-top {
  z-index: 9035;
}
</style>