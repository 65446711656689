<template>
  <p>
    <slot name="header"><label class="form-label">{{ header }}:&nbsp;</label></slot>
    <template v-if="editable">
      <slot name="editor">
        <input ref="inputField" class="form-control"
        :type="inputType" v-model="model" :required="isRequired"
        :min="min" :max="max" :minlength="minlength" :maxlength="maxlength" :pattern="pattern"/>
        <div class="text-danger py-1">{{ validationMessage }}</div>
      </slot>
    </template>
    <template v-else>
      <slot name="body"><span id="body">{{ body ? body : "-" }}</span></slot>
    </template>
  </p>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    validationMessage: {
      type: String,
      required: false
    },
    body: {
      required: false
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    minlength: {
      type: Number,
      required: false
    },
    maxlength: {
      type: Number,
      required: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.body
      },
      set(newValue) {
        this.$emit('body', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#body {
  font-size: 1.3rem;
}
</style>
