import { createRouter, createWebHistory } from 'vue-router';

import store from "@/store.js"

import Login from '@/views/Login.vue';
import CancelLogin from '@/views/CancelLogin.vue';
import Profile from '@/views/Profile.vue';
import Form from '@/views/Form.vue';
import FormContinue from '@/views/FormContinue.vue';
import Orders from '@/views/Orders.vue';
import Staff from '@/views/Staff.vue';
import StaffCreate from '@/views/StaffCreate.vue';
import AffiliateLinks from '@/views/AffiliateLinks.vue';
import StaffCreateUser from '@/views/StaffCreateUser.vue';
import Invoicing from '@/views/Invoicing.vue';
import ThankYou from '@/views/ThankYou.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        async beforeEnter(to, from, next) {
            next({ name: "form-create" });
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: "titles.login",
            navbar: false,
        }
    },
    {
        path: '/cancel-login/:uuid',
        name: 'cancel-login',
        component: CancelLogin,
        meta: {
            title: "titles.cancelLogin",
            navbar: false,
        }
    },
    {
        path: '/logout/',
        name: 'logout',
        async beforeEnter(to, from, next) {
            // after logout redirect to login
            await store.getters.api.get("/auth/logout");
            store.commit("saveUserProfile", { permissions: [] });
            next({ name: "login" });
        }
    },
    {
        path: '/staff',
        name: 'staff',
        component: Staff,
        meta: {
            title: "titles.staff",
            navbar: false,
        }
    },
    {
        path: '/staff/:uuid/create',
        name: 'staff-user-create',
        component: StaffCreateUser,
        meta: {
            title: "titles.staffCreateUser",
            navbar: false,
        }
    },
    {
        path: '/staff-create',
        name: 'staff-create',
        component: StaffCreate,
        meta: {
            title: "titles.staff",
            navbar: false,
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
            title: "titles.orders",
            navbar: true,
        }
    },
    {
        path: '/invoicing',
        name: 'invoicing',
        component: Invoicing,
        meta: {
            title: "titles.invoicing",
            navbar: true,
        }
    },
    {
        path: '/links',
        name: 'links',
        component: AffiliateLinks,
        meta: {
            title: "titles.links",
            navbar: true,
        }
    },
    {
        path: '/form-create',
        name: 'form-create',
        component: Form,
        meta: {
            title: "titles.form",
            navbar: false,
        }
    },
    {
        path: '/form-done',
        name: 'form-done',
        component: ThankYou,
        meta: {
            title: "titles.thankYou",
            navbar: false,
        }
    },
    {
        path: '/continue/:uuid',
        name: 'form-continue',
        component: FormContinue,
        meta: {
            title: "titles.form",
            navbar: false,
        }
    },
    {
        path: '/form/:uuid',
        name: 'form',
        component: Form,
        meta: {
            title: "titles.form",
            navbar: false,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: "titles.profile",
            navbar: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const noLoginPaths = ['index', 'login', 'logout', 'cancel-login', 'form-create', 'form-continue', 'staff-user-create', 'form-done']
    console.log(to.path);
    if (store.getters.isLoggedIn || noLoginPaths.includes(to.name)) {
        next();
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
})

export default router;