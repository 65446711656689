<template>
    <Info :popupHeader="$t('sections.popup.header')" styles="btn">
        <template v-slot:popup>
            <div class="row w-100 align-items-center">
                Nápověda k sekci
            </div>
        </template>
    </Info>
</template>

<script>
import Info from "@/components/Info.vue"
export default {
    components: {
        Info
    },
}
</script>

<style lang="scss">
.material_photo {
    max-width: 100%;
    min-width: 300px;
}
</style>