<template>
    <div>
        <EditableRow :header="$t('staff.pricePerLabel')" :editable="true" :inputType="'number'"
          :body="price" min="0"
          @body="(newValue) => price = newValue" />
        <label class="form-label">{{ $t("staff.functions") }}:&nbsp;</label>
        <select v-model="selectedFunction" class="form-select">
            <option v-for="func in functions" :key="func.id" :value="func.id">{{ $t("functions." + func.name) }}</option>
        </select>
        <button v-if="!link" @click="generateLink()" class="btn btn-lg btn-primary mx-auto mt-3 mb-3 text-white">
            {{ $t("staff.createLink") }}</button>
        <div v-else>
            <label class="form-label mt-3">{{ $t("staff.staffCreateLink") }}:&nbsp;</label><br>
            <p>{{ link }}</p>
            <p>Odkaz byl zkopírován do schránky</p>
        </div>
    </div>
  </template>

  <script>
  import EditableRow from "@/components/EditableRow.vue"

  export default {
    components: {
        EditableRow
    },
    data() {
        return {
            price: 0,
            functions: [],
            selectedFunction: null,
            link: null
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        generateLink: function () {
            this.$store.getters.api.post("/staff/create", {price: this.price, function_id: this.selectedFunction}).then((response) => {
                this.link = response.data.link;
                navigator.clipboard.writeText(this.link);
            });
        },
          loadData: function () {
                this.$store.getters.api.get("/functions").then((response) => {
                    this.functions = response.data.functions;
            })
        }
    }
  }
  </script>

  <style lang="scss">
  </style>