<template>
  <div>
    <div class="row p-3">
      <div class="col-12">
        <h2 class="pb-3 text-center">{{ $t("form.photos") }}</h2>
        <h3 class="pb-3 pt-4 text-center">{{ $t("form.photosSectionHeader2") }}</h3>
        <p class="pb-3 text-center lead">{{ $t("form.photosSectionLabel2") }}</p>
        <Section :editable="editable" :sections="data.sectionsWindows"
                :hideCompositions="true" :lockSections="true"
                :sectionDescription="'form.photoMaterialDescription'"
                @updatePreview="updatePreview" @saveFileChanges="saveFileChanges" />
      </div>
    </div>
    <PopupAttachment ref="attachmentModal" :header="header" :editable="$store.getters.hasPerm('review_orders')" :fileUrl="fileUrl" />
  </div>
</template>

<script>
import Section from "@/components/Section.vue"
import PopupAttachment from "@/components/PopupAttachment.vue";

export default {
  components: {
    Section,
    PopupAttachment
  },
  emits: ['saveFileChanges'],
  props: {
    data: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      fileUrl: "#",
      header: ""
    }
  },
  methods: {
    updatePreview(fileObject, fileUrl) {
      this.fileUrl = fileUrl;
      this.header = fileObject.name;
      this.$refs.attachmentModal.openModal(fileObject.slug);
    },
    saveFileChanges() {
      this.$emit('saveFileChanges');
    }
  }
}
</script>

<style lang="scss">
</style>