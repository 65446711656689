<template>
  <div>
    <div v-if="this.$store.getters.hasPerm('view_customers')" class="row p-4">
      <div class="col-1 mb-4"><strong>{{ $t("orders.order") }}</strong></div>
      <div class="col-2 mb-4"><strong>{{ $t("orders.date") }}</strong></div>
      <div class="col-2 mb-4"><strong>{{ $t("orders.term") }}</strong></div>
      <div class="col-3 mb-4"><strong>{{ $t("orders.customer") }}</strong></div>
      <div class="col-3 mb-4"><strong>{{ $t("orders.technician") }}</strong></div>
      <div class="col-1 mb-4"><strong>{{ $t("orders.label") }}</strong></div>
      <template v-for="(order, index) in orders" :key="index">
        <div class="col-1 mb-3"><router-link :to="'/form/' + order.slug">{{ order.id }}</router-link></div>
        <div class="col-2 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.created_at }}</div>
        <div class="col-2 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ getDateWithOffset(order.days_to_complete) }}</div>
        <div class="col-3 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.user.first_name }} {{ order.user.last_name }}</div>
        <div v-if="order.technical_specialist" class="col-3 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.technical_specialist.first_name }} {{ order.technical_specialist.last_name }}</div>
        <div v-else class="col-3 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">-</div>
        <div class="col-1 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.energy_label ? $t("utils.yes") : $t("utils.no") }}</div>
      </template>
    </div>
    <div v-else class="row p-4">
      <div class="col-1 mb-4"><strong>{{ $t("orders.order") }}</strong></div>
      <div class="col-3 mb-4"><strong>{{ $t("orders.date") }}</strong></div>
      <div class="col-3 mb-4"><strong>{{ $t("orders.term") }}</strong></div>
      <div class="col-4 mb-4"><strong>{{ $t("orders.technician") }}</strong></div>
      <div class="col-1 mb-4"><strong>{{ $t("orders.label") }}</strong></div>
      <template v-for="(order, index) in orders" :key="index">
        <div class="col-1 mb-3"><router-link :to="'/form/' + order.slug">{{ order.id }}</router-link></div>
        <div class="col-3 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.created_at }}</div>
        <div class="col-3 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ getDateWithOffset(order.days_to_complete) }}</div>
        <div v-if="order.technical_specialist" class="col-4 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.technical_specialist.first_name }} {{ order.technical_specialist.last_name }}</div>
        <div v-else class="col-4 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">-</div>
        <div class="col-1 mb-3" :class="getColorClass(order.days_to_complete, order.energy_label)">{{ order.energy_label ? $t("utils.yes") : $t("utils.no") }}</div>
      </template>
    </div>
    <Pagination
    :totalCount="totalCount"
    :perPage="perPage"
    :currentPage="currentPage"
    @page-changed="updatePageData"
    @update:per-page="updatePerPage"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      orders: [],
      totalCount: 0,
      perPage: 10,
      currentPage: 1
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const data = {
        params: {
          current_page: this.currentPage,
          per_page: this.perPage
        }
      }
      this.$store.getters.api.get('/orders', data).then((response) => {
        this.orders = response.data.orders;
        this.totalCount = response.data.total;
      });
    },
    updatePageData(page) {
      this.currentPage = page;
      this.loadData();
    },
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.loadData();
    },
    getDateWithOffset(days) {
      let date = new Date(); // today
      date.setDate(date.getDate() + days);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      return `${day}.${month}.${year}`;
    },
    getColorClass(days, completed) {
      if (completed) {
        return " green";
      } else if (days < 0) {
        return " red";
      }
      return "";
    }
  }
}
</script>

<style lang="scss">
  .green {
    color: rgb(0, 185, 0);
  }
  .red {
    color: red;
  }
</style>