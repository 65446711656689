<template>
  <div v-if="this.$store.getters.hasPerm('view_affiliatelink')">
    <div class="row p-4">
      <div v-if="this.$store.getters.hasPerm('add_affiliatelink')" class="col-12 mb-2 input-group">
        <input class="form-control" type="text" :placeholder="$t('links.placeholder')" v-model="name" />
        <button class="btn btn-primary text-white" @click="addLink">
          {{ $t("links.addLink") }}
        </button>
      </div>
      <div class="col-12 p-3 mb-2">
        <div class="form-check form-switch d-flex">
          <input type="checkbox" class="form-check-input" id="showDeactivated" role="switch" v-model="showDeactivated">
          <label class="form-label form-check-label ms-2" for="showDeactivated">{{ $t("links.showDeactivated") }}</label>
        </div>
      </div>
      <div class="col-3 mb-3 ps-3"><strong>{{ $t("links.name") }}</strong></div>
      <div class="col-7 mb-3"><strong>{{ $t("links.slug") }}</strong></div>
      <div class="col-2 mb-3"><strong>{{ $t("links.active") }}</strong></div>
      <template v-for="(link, index) in links" :key="index">
        <template v-if="link.active || showDeactivated">
          <div class="col-3 my-2 ps-3">{{ link.name }}</div>
          <div class="col-7 my-2"><router-link :to="'/form-create?af=' + link.slug">{{ baseUrl + link.slug }}</router-link></div>
          <div class="col-2 my-2 d-flex">
            <span>{{ link.active ? $t("utils.yes") : $t("utils.no") }}</span>
            <button v-if="this.$store.getters.hasPerm('change_affiliatelink')" class="btn btn-sm btn-outline-dark ms-auto mb-auto" @click="activateDeactivate(link)">
              {{ link.active ? $t("links.deactivate") : $t("links.activate") }}
            </button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { CONFIG } from "@/config.js"

export default {
  data() {
    return {
      links: [],
      name: "",
      showDeactivated: false
    }
  },
  created() {
    this.loadData();
  },
  computed: {
    baseUrl() {
      return CONFIG.vueAppUrl + "/form-create?af=";
    }
  },
  methods: {
    loadData() {
      this.$store.getters.api.get('/affiliate').then((response) => {
        this.links = response.data.links;
      });
    },
    addLink() {
      this.$store.getters.api.post('/affiliate/add', {"name": this.name}).then((_response) => {
        this.name = "";
        this.loadData();
      });
    },
    activateDeactivate(link) {
      this.$store.getters.api.put('/affiliate/' + link.slug, {"active": !link.active}).then((_response) => {
        link.active = !link.active;
      });
    }
  }
}
</script>

<style lang="scss">
</style>