<template>
  <div ref="modalDiv" class="modal fade" tabindex="-1" aria-labelledby="popup" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body position-relative p-0">
          <embed :src="fileUrl" class="preview">
          <div class="mb-3 p-4 d-flex justify-content-between">
            <button type="button" class="btn btn-primary text-white" @click="downloadFile()">
              {{ $t('utils.download') }}
            </button>
            <button type="button" class="btn btn-dark" data-bs-dismiss="modal">{{ $t("utils.close") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  props: {
    fileUrl: {
      type: String,
      required: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    openModal() {
      const myModal = new Modal(this.$refs.modalDiv)
      myModal.show()
    },
    downloadFile() {
      const link = document.createElement('a');
      link.href = this.fileUrl;
      link.download = "document.pdf"; // Specifies the default filename.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
}
</script>


<style scoped lang="scss">
.preview {
  width: 100%;
  object-fit: contain;
  min-height: 70vh;
  border-radius: 25px 25px 0px 0px;
}

.modal-content {
  border: 0px;
  border-radius: 25px;
}

</style>