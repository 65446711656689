<template>
  <div>
    <div class="row p-3">
      <div class="col-12">
        <h2 class="pb-3 text-center">{{ $t("form.contactDetails") }}</h2>
        <EditableRow :header="$t('form.firstName')" :editable="editable" :body="data.modelData.first_name"
          :isRequired="true"
          @body="(newValue) => handleBodyChange('first_name', newValue)" />
        <EditableRow :header="$t('form.lastName')" :editable="editable" :body="data.modelData.last_name"
          :isRequired="true"
          @body="(newValue) => handleBodyChange('last_name', newValue)" />
        <EditableRow :header="$t('form.email')" :editable="editable" :inputType="'email'" :body="data.modelData.email"
          :isRequired="true"
          @body="(newValue) => handleBodyChange('email', newValue)" />
        <EditableRow :header="$t('form.phone')" :editable="editable" :inputType="'phone'" :body="data.modelData.phone"
          :isRequired="true"
          @body="(newValue) => handleBodyChange('phone', newValue)" />
        <h2 class="pb-3 text-center">{{ $t("form.invoicingDetails") }}</h2>
        <EditableRow :header="$t('form.invoicing')" :editable="editable" >
          <template v-slot:editor>
            <div class="form-check form-switch d-flex">
              <input class="form-check-input" id="invoicing" type="checkbox" role="switch" v-model="data.modelData.invoicing" />
              <label class="form-label form-check-label ms-2" for="invoicing">{{ data.modelData.invoicing ? $t('utils.yes') : $t('utils.no') }}</label>
            </div>
          </template>
        </EditableRow>
        <EditableRow v-if="data.modelData.invoicing" :header="$t('form.company')" :editable="editable" :body="data.modelData.company"
          @body="(newValue) => handleBodyChange('company', newValue)" />
        <EditableRow v-if="data.modelData.invoicing" :header="$t('form.id')" :editable="editable" :body="data.modelData.companyId"
          @body="(newValue) => handleBodyChange('companyId', newValue)" />
        <EditableRow v-if="data.modelData.invoicing" :header="$t('form.vat')" :editable="editable" :body="data.modelData.companyVat"
          @body="(newValue) => handleBodyChange('companyVat', newValue)" />
        <EditableRow :header="$t('form.street')" :editable="editable" :body="data.jsonData.invoicingAddress.street"
          :isRequired="true"
          @body="(newValue) => handleInvoicingAddressChange('street', newValue)" />
        <EditableRow :header="$t('form.city')" :editable="editable" :body="data.jsonData.invoicingAddress.city"
          :isRequired="true"
          @body="(newValue) => handleInvoicingAddressChange('city', newValue)" />
        <EditableRow :header="$t('form.zip')" :editable="editable" :body="data.jsonData.invoicingAddress.zip"
          :isRequired="true"
          @body="(newValue) => handleInvoicingAddressChange('zip', newValue)" />
        <template v-if="isMyOrder">
          <div class="form-check mt-5">
            <input class="form-check-input" id="terms" type="checkbox" v-model="data.modelData.terms" required />
            <label class="form-label form-check-label ms-2" for="terms">{{ $t('form.iAgree') }} <a href="#terms" @click="viewTerms('/vop.pdf')">{{ $t('form.terms') }}</a></label>
          </div>
          <div class="form-check">
            <input class="form-check-input" id="gdpr" type="checkbox" v-model="data.modelData.gdpr" required />
            <label class="form-label form-check-label ms-2" for="gdpr">{{ $t('form.iAgree') }} <a href="#gdpr" @click="viewTerms('/gdpr.pdf')">{{ $t('form.gdpr') }}</a></label>
          </div>
        </template>
      </div>
    </div>
  </div>
  <PopupTerms ref="terms" :fileUrl="fileUrl" />
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"
import PopupTerms from "@/components/PopupTerms.vue"

export default {
  components: {
    EditableRow,
    PopupTerms
  },
  emits: ['updateModel', 'updateInvoicingAddress'],
  props: {
    data: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    isMyOrder: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      fileUrl: ""
    }
  },
  methods: {
    handleBodyChange(property, newValue) {
      this.$emit('updateModel', { property, newValue });
    },
    handleInvoicingAddressChange(property, newValue) {
      this.$emit('updateInvoicingAddress', { property, newValue });
    },
    viewTerms(fileUrl) {
      this.fileUrl = fileUrl;
      this.$refs.terms.openModal();
    },
  }
}
</script>

<style lang="scss">
</style>