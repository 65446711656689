<template>
  <div>
    <div v-if="$store.getters.hasPerm('add_user')" class="p-4">
      <router-link :to="'/staff-create'">{{ $t("staff.create") }}</router-link>
    </div>
    <div class="row p-4">
      <div class="col-2 mb-4"><strong>{{ $t("staff.name") }}</strong></div>
      <div class="col-3 mb-4"><strong>{{ $t("staff.email") }}</strong></div>
      <div class="col-2 mb-4"><strong>{{ $t("staff.phone") }}</strong></div>
      <div class="col-2 mb-4"><strong>{{ $t("staff.functions") }}</strong></div>
      <div class="col-1 mb-4"><strong>{{ $t("staff.maximumOrderCount") }}</strong></div>
      <div class="col-1 mb-4"><strong>{{ $t("staff.action") }}</strong></div>
      <template v-for="(user, index) in users" :key="index">
        <div class="col-2 mb-3">{{ user.name }}</div>
        <div class="col-3 mb-3">{{ user.email }}</div>
        <div class="col-2 mb-3">{{ user.phone }}</div>
        <div class="col-2 mb-3">{{ functionNames(user.functions) }}</div>
        <div class="col-1 mb-3">{{ user.maximum_order_count || '-' }}</div>
        <!-- <div class="col-1 mb-3"><router-link :to="'/staff'">{{ $t("staff.detail") }}</router-link></div> -->
        <div class="col-1 mb-3">-</div>
      </template>
    </div>
    <Pagination
    :totalCount="totalCount"
    :perPage="perPage"
    :currentPage="currentPage"
    @page-changed="updatePageData"
    @update:per-page="updatePerPage"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      users: [],
      totalCount: 0,
      perPage: 10,
      currentPage: 1
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    functionNames: function (functions) {
      var name = ""
      for (var i = 0; i < functions.length; i++){
        if (name == "") {
          name = this.$t("functions." + functions[i].name);
        } else {
          name += ", " + this.$t("functions." + functions[i].name);
        }
      }
      return name;
    },
    loadData() {
      const data = {
        params: {
          current_page: this.currentPage,
          per_page: this.perPage
        }
      }
      this.$store.getters.api.get('/staff', data).then((response) => {
        this.users = response.data.users;
        this.totalCount = response.data.total;
      });
    },
    updatePageData(page) {
      this.currentPage = page;
      this.loadData();
    },
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.loadData();
    },

  }
}
</script>

<style lang="scss">
</style>