<template>
  <div>
    <div class="row p-4">
      <h2 class="mb-4">{{ $t("invoicing.headerOrders") }}</h2>
      <div v-if="orders.length === 0" class="col-12 mb-4">{{ $t("invoicing.noOrders") }}</div>

      <template v-else>
        <template v-if="this.$store.getters.hasPerm('view_customers')">
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.select") }}</strong></div>
          <div class="col-1 mb-4"><strong>{{ $t("invoicing.order") }}</strong></div>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.date") }}</strong></div>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.term") }}</strong></div>
          <div class="col-3 mb-4"><strong>{{ $t("invoicing.customer") }}</strong></div>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.amount") }}</strong></div>

          <template v-for="(order, index) in orders" :key="index">
            <div class="col-2 mb-3"><input type="checkbox" class="form-check-input" :id="`bill${index}`" @change="changeSelected(order.id)"></div>
            <div class="col-1 mb-3"><router-link :to="'/form/' + order.slug">{{ order.id }}</router-link></div>
            <div class="col-2 mb-3">{{ order.created_at }}</div>
            <div class="col-2 mb-3">{{ getDateWithOffset(order.days_to_complete) }}</div>
            <div class="col-3 mb-3">{{ order.user.first_name }} {{ order.user.last_name }}</div>
            <div class="col-2 mb-3">{{ order.amount }} Kč</div>
          </template>
        </template>

        <template v-else>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.select") }}</strong></div>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.order") }}</strong></div>
          <div class="col-3 mb-4"><strong>{{ $t("invoicing.date") }}</strong></div>
          <div class="col-3 mb-4"><strong>{{ $t("invoicing.term") }}</strong></div>
          <div class="col-2 mb-4"><strong>{{ $t("invoicing.amount") }}</strong></div>

          <template v-for="(order, index) in orders" :key="index">
            <div class="col-2 mb-3"><input type="checkbox" class="form-check-input" :id="`bill${index}`" @change="changeSelected(order.id)"></div>
            <div class="col-2 mb-3"><router-link :to="'/form/' + order.slug">{{ order.id }}</router-link></div>
            <div class="col-3 mb-3">{{ order.created_at }}</div>
            <div class="col-3 mb-3">{{ getDateWithOffset(order.days_to_complete) }}</div>
            <div class="col-2 mb-3">{{ order.amount }} Kč</div>
          </template>
        </template>

        <div class="d-flex justify-content-left">
          <button @click="invoiceSelected" class="btn btn-primary mt-3 mb-3 text-white">
            {{ $t("invoicing.billSelected") }}
          </button>
          <button @click="invoiceAll" class="btn btn-primary ms-3 mt-3 mb-3 text-white">
            {{ $t("invoicing.billAll") }}
          </button>
        </div>
      </template>

      <template v-if="items.length > 0">
        <h2 class="mt-3 mb-4">{{ $t("invoicing.headerNewInvoice") }} {{ document_number }}</h2>
        <p v-for="item in items">{{ item }}</p>
        <p>{{ $t("invoicing.totalPrice") }}: {{ total }} Kč {{ $t("invoicing.withoutVat") }}</p>
      </template>

      <h2 class="my-4">{{ $t("invoicing.headerInvoices") }}</h2>
      <div v-if="invoices.length === 0" class="col-12 mb-4">{{ $t("invoicing.noInvoices") }}</div>

      <template v-else>
        <div class="col-2 mb-4"><strong>{{ $t("invoicing.invoiceNumber") }}</strong></div>
        <div class="col-2 mb-4"><strong>{{ $t("invoicing.invoiceDate") }}</strong></div>
        <div class="col-2 mb-4"><strong>{{ $t("invoicing.invoiceItems") }}</strong></div>
        <div class="col-3 mb-4"><strong>{{ $t("invoicing.invoiceAmount") }}</strong></div>
        <div class="col-3 mb-4"><strong>{{ $t("invoicing.document") }}</strong></div>

        <template v-for="(invoice, index) in invoices" :key="index">
          <div class="col-2 mb-3">{{ invoice.invoice.document_number ? invoice.invoice.document_number : "-" }}</div>
          <div class="col-2 mb-3">{{ invoice.invoice.date_of_issue }}</div>
          <div class="col-2 mb-3">{{ invoice.invoice.items.length }}</div>
          <div class="col-3 mb-3">{{ invoice.invoice.total }} Kč</div>
          <div class="col-3 mb-3 d-flex">
            <span>{{ invoice.invoice.attachments ? invoice.invoice.attachments : "-" }}</span>
            <button class="btn btn-sm btn-outline-dark ms-auto mb-auto" data-bs-toggle="modal" data-bs-target="#uploadFile"
              @click="setinvoiceFileId(invoice.invoice.id)">
              {{ $t("utils.upload") }}
            </button>
          </div>
        </template>
        <Popup :header="$t('invoicing.uploadAttachment')" :editable="true" id="uploadFile" @save-data="uploadFileToServer">
          <template v-slot:editor>
            <input type="file" class="form-control" accept="application/pdf" @change="changeFile">
          </template>
        </Popup>
      </template>
    </div>
    <LoadingSpinner :loading="loading"/>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    Popup,
    LoadingSpinner
  },
  data() {
    return {
      orders: [],
      selected: [],
      invoices: [],
      items: [],
      total: 0,
      document_number: "",
      invoiceFileId: null,
      invoiceFile: null,
      loading: false
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.getters.api.get('/orders-invoicing').then((response) => {
        this.orders = response.data.orders;
      });
      this.$store.getters.api.get('/invoices').then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    setinvoiceFileId(id) {
      this.invoiceFileId = id;
    },
    invoiceSelected() {
      this.loading = true;
      if (this.selected.length === 0) {
        return;
      }
      this.$store.getters.api.post('/invoice', {orders: this.selected}).then((response) => {
        this.items = response.data.items;
        this.total = response.data.total;
        this.document_number = response.data.document_number;
        this.loadData();
        this.loading = false;
      });
    },
    invoiceAll() {
      this.loading = true;
      const invoices = [];
      this.orders.forEach((order) => {
        invoices.push(order.id);
      });
      this.$store.getters.api.post('/invoice', {orders: invoices}).then((response) => {
        this.items = response.data.items;
        this.total = response.data.total;
        this.document_number = response.data.document_number;
        this.loadData();
        this.loading = false;
      });
    },
    changeSelected(id) {
      if (this.selected.includes(id)) {
        this.selected.splice(this.selected.indexOf(id), 1);
      } else {
        this.selected.push(id);
      }
    },
    getDateWithOffset(days) {
      let date = new Date(); // today
      date.setDate(date.getDate() + days);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      return `${day}.${month}.${year}`;
    },
    changeFile(event) {
      this.invoiceFile = event.target.files[0];
    },
    uploadFileToServer: function () {
      if (!this.invoiceFile) {
        return;
      }
      this.loading = true;
      let formData = new FormData();
      formData.append('attachment', this.invoiceFile);
      this.$store.getters.api.post(`/invoice/${this.invoiceFileId}/attachment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        this.loadData();
        this.loading = false;
      }.bind(this));
    },
  }
}
</script>

<style lang="scss">
</style>