<template>
  <div>
    <template v-if="editable">
      <ul class="list-group list-group-flush">
        <template v-for="(material, index) in materials" :key="index">
          <li class="list-group-item px-2">
            <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
              <div class="d-flex flex-nowrap align-items-center">
                <div @click="moveUp(index)" class="me-1" :class="{'cursor_pointer': index != 0}">
                  <icon icon="ArrowUp" :class="{'opacity-0': index == 0}"/>
                </div>
                <div @click="moveDown(index)" class="me-2"  :class="{'cursor_pointer': index != materials.length - 1}">
                  <icon icon="ArrowDown" :class="{'opacity-0': index == materials.length - 1}"/>
                </div>
                <template v-if="!material.bool">
                  <input class="form-control" type="number" v-model="material.thickness" required min="1" />
                  <span>&nbsp;mm</span>
                </template>
                <template v-else>
                  <div class="form-check ms-2">
                    <input type="radio" class="form-check-input"  v-model="material.thickness" :value="1"/>
                    <label class="form-check-label ms-2">{{ $t("utils.yes") }}</label>
                  </div>
                  <div class="form-check ms-2 me-2">
                    <input type="radio" class="form-check-input"  v-model="material.thickness" :value="0"/>
                    <label class="form-check-label ms-2">{{ $t("utils.no") }}</label>
                  </div>
                </template>
              </div>
              <div class="input-group mt-3 mt-md-0 ms-md-2 float-right">
                <template v-if="!material.bool">
                  <select class="form-select" aria-label="material" v-model="material.type_id" required @change="resetMaterial(index)">
                    <option :value="''">{{ $t("form.chooseMaterial") }}</option>
                    <template v-for="(item, optionIndex) in materialOptions" :key="optionIndex">
                      <option :value="item.id">{{ item.name }}</option>
                    </template>
                  </select>
                  <MaterialsInfo :materialOptions="materialOptions"/>
                  <button class="btn btn-sm btn-outline-danger"
                    @click="materials.splice(index, 1)">
                    <icon icon="Trash" />
                  </button>
                </template>
                <template v-else>
                  <select class="form-select" aria-label="material">
                    <option :value="0">{{ material.type }}</option>
                  </select>
                </template>
              </div>
            </div>
            <input v-if="material.type_id === -1" class="form-control mt-2" type="text" v-model="material.type" required />
          </li>
        </template>
        <li class="list-group-item px-2 darker-background addLayer">
          <ul class="nav">
            <li class="nav-item">
              <button class="nav-link py-0 nav-size-1" @click="addLayer(materials.length)">+&nbsp;{{ $t("form.addLayer") }}</button>
            </li>
          </ul>
        </li>
      </ul>
    </template>
    <template v-else>
      <ul class="list-group list-group-flush">
        <template v-for="(material, index) in materials" :key="index">
          <li class="list-group-item">
            <span v-if="!material.bool">{{ material.thickness }} mm - {{ getMaterialName(material) }}</span>
            <span v-else> {{ $t(material.thickness? "utils.yes": "utils.no") }} - {{ getMaterialName(material) }}</span>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
import MaterialsInfo from "@/components/MaterialsInfo.vue"

export default {
  components: {
    MaterialsInfo
  },
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    materials: {
      type: Array,
      required: true
    },
    materialOptions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getMaterialName(material) {
      if (material && material.type) {
        return material.type;
      }

      const resultMaterial = this.materialOptions.find(item => item.id == material.type_id);

      if (resultMaterial) {
        return resultMaterial.name;
      }

      return '';
    },
    resetMaterial(index) {
      this.materials[index].type = null;
    },
    addLayer(index) {
      if (index === this.materials.length - 1) {
        this.materials.push({
          type_id: 0,
          type: null,
          thickness: 0
        });
      } else {
        this.materials.splice(index + 1, 0, {
          type_id: 0,
          type: null,
          thickness: 0
        });
      }
    },
    moveUp(index) {
      if (index > 0) {
        this.materials.splice(index - 1, 0, JSON.parse(JSON.stringify(this.materials[index])));
        this.materials.splice(index + 1, 1);
      }
    },
    moveDown(index) {
      if (index < this.materials.length - 1) {
        this.materials.splice(index + 2, 0, JSON.parse(JSON.stringify(this.materials[index])));
        this.materials.splice(index, 1);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/main.scss";

.btn-outline-primary:hover {
  color: white;
}

.darker-background {
  background-color: $grey;
}

.cursor_pointer {
  cursor: pointer;
}

.addLayer {
  border-radius: 0px 0px var(--bs-card-border-radius) var(--bs-card-border-radius) !important;
}
</style>
