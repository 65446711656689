<template>
    <div class="row justify-content-center align-items-center vh-100 w-100 m-0">
        <div class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 form-box">
                <h2>{{ messageText }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: "",
            slug: "",
            pin: ""
        }
    },
    computed: {
      messageText() {
        if (this.message && this.message === "success") {
            return this.$t("cancelLogin.success")
        } else if (this.message && this.message === "fail") {
            return this.$t("cancelLogin.fail")
        }
        return this.$t("cancelLogin.title");
      }
    },
    methods: {
        cancelPin: function () {
            this.$store.getters.api.delete("/order-continue/" + this.slug + "?pin=" + this.pin).then(
                (response) => {
                    if (response.data.success && response.data.success === "User verification request successfully deactivated.") {
                        this.message = "success";
                    } else {
                        this.message = "fail";
                    }
                }
            )
        },
    },
    created: function () {
        if (this.$route.params.uuid && this.$route.params.uuid !== "") {
            this.slug = this.$route.params.uuid;
            this.pin = this.$route.query.pin;
            this.cancelPin();
        }
    }
}
</script>

<style lang="scss">
</style>