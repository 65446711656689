<template>
    <div class="warning" :style="{top: pos, right: pos}">
        <icon icon="WarningCircle" :height="size" :width="size"/>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            required: false,
            default: 30
        },
    },
    computed: {
        pos: function () {
            return (-1 * this.size / 4) + 'px';
        }
    }
}
</script>

<style scoped lang="scss">
.warning {
    position: absolute;
    z-index: 9000;
    color: red;
}
</style>