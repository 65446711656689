<template>
  <div>
    <template v-if="editable">
      <template v-for="(composition, index) in compositions" :key="index">
        <div class="pb-3 px-0 d-flex justify-content-between">
          <div class="input-group me-2">
            <input class="form-control" type="text" :placeholder="$t('utils.compositionNameDescription')" v-model="composition.name" />
          </div>
          <button class="btn btn-outline-danger" @click="compositions.splice(index, 1)">
            <icon icon="Trash" />
          </button>
        </div>
        <div class="card mb-3">
          <div class="card-body px-0 pt-1 pb-0">
            <Materials :editable="editable" :materials="composition.materials" :materialOptions="materialOptions" />
          </div>
        </div>
      </template>
      <ul class="nav mb-2" v-if="canAddComposition">
        <li class="nav-item">
          <button class="nav-link py-0 nav-size-2" @click="addComposition">+&nbsp;{{ $t("form.addComposition") }}</button>
        </li>
      </ul>
    </template>
    <template v-else>
      <template v-for="(composition, index) in compositions" :key="index">
        <div class="card mb-3">
          <div class="card-header px-4">
            <h4 class="my-2">{{ composition.name }}</h4>
          </div>
          <div class="card-body px-0 p-1">
            <Materials :editable="editable" :materials="composition.materials" :materialOptions="materialOptions" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Materials from "@/components/Materials.vue"
import Icon from "@/components/Icon.vue"

export default {
  components: {
    Materials,
    Icon
  },
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    compositions: {
      type: Array,
      required: true
    },
    materialOptions: {
      type: Array,
      required: true
    },
    materialDefaultOptions: {
      type: Number,
      required: false,
      default: 1
    },
    canAddComposition: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      base: {
        name: "",
        materials: [
          {
            type_id: "",
            type: "",
            thickness: 0
          }
        ],
      },
      floors: {
        name: "",
        materials: [
          {
            type_id: 0,
            type: "Interiérová omítka",
            thickness: 15
          },
          {
            type_id: 0,
            type: null,
            thickness: 0
          },
          {
            type_id: 0,
            type: "Exteriérová omítka",
            thickness: 15
          }
        ],
      }
    }
  },
  methods: {
    addComposition() {
      if (this.materialDefaultOptions == 1) {
        this.compositions.push(base);
      }
      if (this.materialDefaultOptions == 2) {
        this.compositions.push(base);
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
