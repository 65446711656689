<template>
  <div>
    <div class="row p-3">
      <div class="col-12">
        <h2 class="pb-3 text-center">{{ $t("form.buildingDetails") }}</h2>
        <EditableRow ref="buildingAge" :header="$t('form.buildingAge')" :editable="editable" :isRequired="true" :min="1500" :max="new Date().getFullYear()"
          :inputType="'number'" :body="data.buildingAge"
          @body="(newValue) => handleBodyChange('buildingAge', newValue)" />
        <EditableRow ref="floorsAboveGround" :header="$t('form.floorsAboveGround')" :editable="editable" :isRequired="true" :min="1" :max="50"
          :inputType="'number'" :body="data.floorsAboveGround"
          @body="(newValue) => handleBodyChange('floorsAboveGround', newValue)" />
        <EditableRow ref="floorsUnderGround" :header="$t('form.floorsUnderGround')" :editable="editable" :isRequired="true" :min="0" :max="10"
          :inputType="'number'" :body="data.floorsUnderGround"
          @body="(newValue) => handleBodyChange('floorsUnderGround', newValue)" />
        <h2 class="pb-3 text-center">{{ $t("form.buildingAddress") }}</h2>
        <EditableRow ref="buildingStreet" :header="$t('form.buildingStreet')" :editable="editable" :isRequired="true"
          :body="data.buildingAddress.street"
          @body="(newValue) => handleBuildingAddressChange('street', newValue)" />
        <EditableRow ref="buildingCity" :header="$t('form.buildingCity')" :editable="editable" :isRequired="true"
          :body="data.buildingAddress.city"
          @body="(newValue) => handleBuildingAddressChange('city', newValue)" />
        <EditableRow ref="buildingZip" :header="$t('form.buildingZip')" :editable="editable" :isRequired="true"
          :body="data.buildingAddress.zip"
          @body="(newValue) => handleBuildingAddressChange('zip', newValue)" />
      </div>
    </div>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"

export default {
  components: {
    EditableRow
  },
  emits: ['updateJson', 'updateBuildingAddress'],
  props: {
    data: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
    }
  },
  methods: {
    handleBodyChange(property, newValue) {
      this.$emit('updateJson', { property, newValue });
    },
    handleBuildingAddressChange(property, newValue) {
      this.$emit('updateBuildingAddress', { property, newValue });
    },
  }
}
</script>

<style lang="scss">
</style>