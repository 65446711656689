<template>
  <div ref="modalDiv" class="modal fade" tabindex="-1" aria-labelledby="popup" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body position-relative p-0">
          <button v-if="previewType(header) === 'image/jpeg' || previewType(header) === 'image/png'" type="button"
            class="btn-close position-absolute top-0 end-0 p-4" data-bs-dismiss="modal" aria-label="Close"></button>
          <img v-if="previewType(header) === 'image/jpeg' || previewType(header) === 'image/png'" :src="fileUrl" />
          <embed v-else-if="previewType(header) === 'application/pdf'" :src="fileUrl" :type="previewType(header)" class="preview">
          <div class="mb-3 p-4">
            <div class="d-flex justify-content-between">
              <h1 class="fs-4 ms-1 mb-5" id="popup">{{ header }}</h1>
              <button v-if="$store.getters.hasPerm('download_files')" type="button" class="btn btn-primary text-white mb-auto" @click="downloadFile()">
                {{ $t('utils.download') }}
              </button>
            </div>
            <h2 class="fs-4 ms-1 mb-4">{{ $t('form.technicianNote') }}</h2>
            <template v-if="editable">
              <div class="form-check d-flex flex-nowrap align-items-center mb-3">
                <input type="checkbox" class="form-check-input" id="review" v-model="toReview">
                <label class="form-label form-check-label mb-0 ms-2" for="review">{{ $t("form.labelToReview") }}</label>
              </div>
              <textarea class="form-control mb-3" v-model="input"></textarea>
              <div class="d-flex">
                <button type="button" class="btn btn-primary text-white ms-auto" data-bs-dismiss="modal" @click="saveData(this.input, this.toReview)">
                  {{ $t('utils.save') }}
                </button>
              </div>
            </template>
            <template v-else>
              <p v-if="toReview" class="ms-1 text-danger">{{ $t("form.labeledToReview") }}</p>
              <p class="ms-1">{{ input }}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  emits: ['save-data'],
  props: {
    header: {
      type: String,
      required: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    fileUrl: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      slug: "",
      input: "",
      toReview: false
    }
  },
  methods: {
    previewType(filename) {
      const parts = filename.split('.');
      if (parts.length > 1 && ['jpg', 'jpeg'].includes(parts[parts.length - 1])) {
        return 'image/jpeg';
      }
      else if (parts.length > 1 && parts[parts.length - 1] === 'png') {
        return 'image/png';
      }
      else if (parts.length > 1 && parts[parts.length - 1] === 'pdf') {
        return 'application/pdf';
      }
      return null;
    },
    saveData(input, toReview) {
      this.$store.getters.api.put('/file/' + this.slug + '/info', {"description": input, "toReview": toReview})
      .then((_response) => {
        this.slug = "";
        this.input = "";
        this.toReview = false;
      });
    },
    setInput(input) {
      this.input = input;
    },
    openModal(slug) {
      this.loadData(slug);
      this.slug = slug;
      const myModal = new Modal(this.$refs.modalDiv)
      myModal.show()
    },
    loadData: function (slug) {
      this.input = "";
      this.toReview = false;
      this.$store.getters.api.get('/file/' + slug + '/info').then((response) => {
        this.input = response.data.description;
        this.toReview = response.data.to_review;
      });
    },
    downloadFile() {
      const link = document.createElement('a');
      link.href = this.fileUrl;
      link.download = this.header; // Specifies the default filename.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
}
</script>


<style scoped lang="scss">
img {
  width: 100%;
  object-fit: contain;
  border-radius: 25px 25px 0px 0px;
}

.preview {
  width: 100%;
  object-fit: contain;
  min-height: 50vh;
  border-radius: 25px 25px 0px 0px;
}

.modal-content {
  border: 0px;
  border-radius: 25px;
}

</style>