<template>
    <Info :popupHeader="$t('materials.popup.header')" styles="btn btn-outline-primary btn_color_white">
        <template v-slot:popup>
            <div class="row w-100 align-items-end">
                <div v-for="(item, optionIndex) in materialOptions" :key="optionIndex" class="col-12 col-md-6 col-lg-4 text-center mb-3">
                    <template v-if="item.id > 0">
                        <div class="material_heading">
                            <h1 class="my-0">{{ item.name }}</h1>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                        <img :src="this.$store.getters.baseApiUrl + '/materials/' + item.id + '/photo'" class="material_photo">
                    </template>
                </div>
            </div>
        </template>
    </Info>
</template>

<script>
import Info from "@/components/Info.vue"
export default {
    components: {
        Info
    },
    props: {
        materialOptions: {
            type: Array,
            required: true
        },
    }

}
</script>

<style lang="scss">
.material_photo {
    margin-top: 30px;
    max-width: 100%;
    min-width: 300px;
}
</style>