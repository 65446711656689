<template>
  <div>
    <div class="row justify-content-start">
      <div class="col-12 col-md-2 fixed-top" :class="{'mt-5': this.$store.getters.hasPerm('view_navbar')}">
        <nav class="p-md-3" aria-label="Form pagination">
          <ul class="pagination mb-0 pt-2 pt-md-0 pb-3 pb-md-0 justify-content-center user-select-none flex-row flex-md-column">
            <li v-if="!this.$store.getters.hasPerm('view_navbar')" class="mx-auto">
              <img id="logo" src="/RayHome-logo-white.png" alt="RayHome Energy" />
            </li>
          </ul>
          <ul class="pagination justify-content-center user-select-none flex-row flex-md-column ps-5 ps-md-0">
            <li class="page-item mb-3 mt-md-5 mb-md-3 me-2 mx-md-auto position-relative">
              <a
                class="page-link circle" :class="{active: showForm === 1}"
                href="#" @click="setPage(1)"
                >1</a>
              <Warning v-if="warnings['1']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.1") }}</div>
              <div id="line_height" class="mx-auto position-absolute start-50 d-none d-md-inline"></div>
              <div id="line_width" class="mx-auto position-absolute start-50 d-inline d-md-none"></div>
            </li>
            <li class="page-item mb-3 mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 2}" href="#" @click="setPage(2)">2</a>
              <Warning v-if="warnings['2']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.2") }}</div>
            </li>
            <li class="page-item mb-3 mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 3}" href="#" @click="setPage(3)">3</a>
              <Warning v-if="warnings['3']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.3") }}</div>
            </li>
            <li class="page-item mb-3 mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 4}" href="#" @click="setPage(4)">4</a>
              <Warning v-if="warnings['4']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.4") }}</div>
            </li>
            <li class="page-item mb-3 mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 5}" href="#" @click="setPage(5)">5</a>
              <Warning v-if="warnings['5']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.5") }}</div>
            </li>
            <li class="page-item mb-3 mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 6}" href="#" @click="setPage(6)">6</a>
              <Warning v-if="warnings['6']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.6") }}</div>
            </li>
            <li class="page-item mx-md-auto mb-md-3 me-2 position-relative">
              <a class="page-link circle" :class="{active: showForm === 7}" href="#" @click="setPage(7)">7</a>
              <Warning v-if="warnings['7']"/>
              <div class="form_part_tooltip">{{ $t("tooltips.titles.7") }}</div>
            </li>
          </ul>
        </nav>
      </div>
      <form class="col-12 col-md-8 offset-md-3 top_offset" @submit.prevent="redirect" ref="form">
        <Form1 v-if="showForm === 1 && ($store.getters.hasPerm('view_customers') || isMyOrder || !$store.getters.isLoggedIn)"
          :data="{modelData: this.modelData, jsonData: this.jsonData}" :editable="canEditForm"
          :isMyOrder="isMyOrder" @updateModel="handleModelUpdate" @updateInvoicingAddress="handleInvoicingAddressUpdate" />
        <Form2 ref="form2" v-else-if="showForm === 2" :data="jsonData" :editable="canEditForm" @updateJson="handleJsonUpdate" @updateBuildingAddress="handleBuildingAddressUpdate" />
        <Form3 v-else-if="showForm === 3" :data="jsonData" :editable="canEditForm" :materialOptions="materialOptions" @saveFileChanges="saveData" />
        <Form4 v-else-if="showForm === 4" :data="jsonData" :editable="canEditForm" @saveFileChanges="saveData" />
        <Form5 v-else-if="showForm === 5" :data="jsonData" :editable="canEditForm" :materialOptions="materialOptions" @sendForm="sendForm" @saveFileChanges="saveData" />
        <Form6 v-else-if="showForm === 6" :data="jsonData" :editable="canEditForm" :materialOptions="materialOptions" @sendForm="sendForm" @saveFileChanges="saveData" />
        <Form7 v-else-if="showForm === 7" :data="jsonData" :editable="canEditForm" :materialOptions="materialOptions" @sendForm="sendForm" @saveFileChanges="saveData" />
        <div v-if="showForm === 1" class="row p-3">
          <div v-if="$store.getters.hasPerm('review_orders')" class="col-12">
            <h3 class="pb-3 text-center">{{ $t("form.reviewInfo") }}</h3>
            <textarea class="form-control" v-model="requestInfo" />
            <ul class="nav">
              <li class="nav-item">
                <button type="button" class="nav-link nav-size-3" @click="sendReview">{{ $t("utils.save") }}</button>
              </li>
            </ul>
          </div>
          <div v-else-if="!$store.getters.hasPerm('review_orders') && requestInfo" class="col-12">
            <h3 class="pb-3 text-center">{{ $t("form.reviewInfo") }}</h3>
            <p>{{ requestInfo }}</p>
          </div>
          <div v-if="!energyLabel && $store.getters.hasPerm('review_orders')" class="col-12">
            <h3 class="py-3 text-center">{{ $t("form.energyLabel") }}</h3>
            <label for="formFile" class="form-label ms-2">{{ $t("form.uploadFiles") }}:</label>
            <input class="form-control form-control-lg mb-3" type="file" id="formFile" @change="handleFileChange($event)">
          </div>
          <div v-if="energyLabel && ($store.getters.hasPerm('view_energy_label') || isPaid)" class="list-group col-12 px-3">
            <h3 class="py-3 text-center">{{ $t("form.energyLabel") }}</h3>
            <button type="button" class="list-group-item list-group-item-action" @click="downloadPhoto()">
              {{ slug }}
            </button>
          </div>
          <ul v-else-if="energyLabel" class="list-group col-12 px-3">
            <li class="list-group-item">
              {{ $t("form.energyLabelUnavailable") }}
            </li>
          </ul>
        </div>
        <div v-if="canEditForm" class="px-3 d-flex justify-content-center align-items-center text-center flex-wrap">
          <button v-if="showForm > 1" type="button" class="btn btn-lg btn-primary mx-auto mt-3 mb-3 text-white btn_min_size" @click="prevPage">
            {{ $t("form.previous") }}
          </button>
          <button v-if="showForm < 7" type="button" class="btn btn-lg btn-primary mx-auto mt-3 mb-3 text-white btn_min_size" @click="nextPage">
            {{ $t("form.next") }}
          </button>
        </div>
        <div v-if="canEditForm && this.slug && this.slug !== ''" class="px-3 d-flex justify-content-center align-items-center text-center">
          <button type="button" class="btn btn-lg btn-primary mx-auto mt-3 mb-3 text-white text-nowrap" @click="saveData">
            {{ $t("form.save") }}
          </button>
        </div>
        <div>
          <p class="text-center hidden_link mt-5">
            <router-link to="/login">{{ $t("links.administration") }}</router-link>
          </p>
        </div>
      </form>
    </div>
    <LoadingSpinner :loading="loading"/>
  </div>
</template>

<script>
import Form1 from "@/components/Form1.vue";
import Form2 from "@/components/Form2.vue";
import Form3 from "@/components/Form3.vue";
import Form4 from "@/components/Form4.vue";
import Form5 from "@/components/Form5.vue";
import Form6 from "@/components/Form6.vue";
import Form7 from "@/components/Form7.vue";
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Warning from '@/components/Warning.vue';

export default {
  components: {
    Form1,
    Form2,
    Form3,
    Form4,
    Form5,
    Form6,
    Form7,
    Warning,
    LoadingSpinner
  },
  data() {
    return {
      showForm: 1,
      loading: false,
      loaded: false,
      updated: false,
      slug: "",
      energyLabel: false,
      isPaid: false,
      isMyOrder: false,
      requestInfo: "",
      warnings: {},
      materialOptions: [],
      modelData: {
        invoicing: false,
        addTechnician: false,
        terms: false,
        gdpr: false,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        companyId: "",
        companyVat: "",
      },
      jsonData: {
        floorsAboveGround: 0,
        floorsUnderGround: 0,
        buildingAge: 0,
        invoicingAddress: {
          street: "",
          city: "",
          zip: ""
        },
        buildingAddress: {
          street: "",
          city: "",
          zip: ""
        },
        note: "",
        sectionsFloors: [],
        sectionsMain: [
          {
            name: "Skladba podlahy na terénu",
            description: "",
            files: [],
            sameCompositionAsFile: true,
            sameCompositionOnWall: true,
            compositions: [
              {
                name: "Skladba podlahy na terénu",
                materials: [
                  {
                    type_id: 0,
                    type: "Beton",
                    thickness: 50
                  },
                  {
                    type_id: 0,
                    type: "Polystyren Bílý",
                    thickness: 0
                  },
                  {
                    type_id: 0,
                    type: "Hydroizolace",
                    bool: true,
                    thickness: 0
                  },
                  {
                    type_id: 0,
                    type: "Beton",
                    thickness: 0
                  }
                ],
              },
              {
                name: "Izolace střecha/stropu vrchního podlaží",
                materials: [
                  {
                    type_id: 0,
                    type: null,
                    thickness: 0
                  }
                ],
              }
            ]
          }
        ],
        sectionsWalls: [
          {
            name: "Severní fasáda",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Západní fasáda",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Jížní fasáda",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Východní fasáda",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          }
        ],
        sectionsWindows: [
          {
            name: "Detail dveří",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Detail oken",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Detail střešních oken",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          },
          {
            name: "Detail garážových vrat",
            description: "",
            fileCanBeUploaded: true,
            files: [],
            compositions: []
          }
        ],
        sectionsOther: [],
        otherCompositions: []
      }
    };
  },
  watch: {
    "$route.params.uuid": {
      handler: function (val) {
        if (val && val !== "" && !this.loaded) {
          this.slug = this.$route.params.uuid;
          this.loadData();
        }
      },
      deep: true,
      immediate: true
    },
    jsonData: {
      handler: function(_val){
        if (this.loaded || this.slug === "") {
          this.updated = true;
        }
      },
      deep: true,
    },
    showForm: function () {
      this.$store.commit("changeFormPart", this.showForm);
    }
  },
  computed: {
    canEditForm() {
      return this.$store.getters.hasPerm("change_orders") || this.$route.name === "form-create";
    }
  },
  methods: {
    redirect: function () {
      this.$router.push({ "name": "form-done" });
    },
    handleModelUpdate({ property, newValue }) {
      this.modelData[property] = newValue;
      if (this.loaded || this.slug === "") {
        this.updated = true;
      }
    },
    handleJsonUpdate({ property, newValue }) {
      this.jsonData[property] = newValue;
    },
    handleBuildingAddressUpdate({ property, newValue }) {
      this.jsonData.buildingAddress[property] = newValue;
    },
    handleInvoicingAddressUpdate({ property, newValue }) {
      this.jsonData.invoicingAddress[property] = newValue;
    },
    nextPage() {
      if (this.validateData() === true) {
        this.showForm = this.showForm + 1;
        this.generateFloors();
        this.saveData();
        setTimeout(() => { window.scrollTo(0, 0) }, 500);
      }
    },
    prevPage() {
      this.showForm = this.showForm - 1;
      this.saveData();
        setTimeout(() => { window.scrollTo(0, 0) }, 500);
    },
    setPage(page) {
      if (this.canEditForm) {
        if (page > this.showForm && this.validateData() === true) {
          this.showForm = this.showForm + 1;
          this.generateFloors();
          this.saveData();
        } else if (page < this.showForm) {
          this.showForm = page;
          this.saveData();
        }
      } else {
        this.showForm = page;
      }
      setTimeout(() => { window.scrollTo(0, 0) }, 500);
    },
    generateFloors() {
      if ((this.jsonData.floorsAboveGround > 0 || this.jsonData.floorsUnderGround > 0) && this.jsonData.sectionsFloors.length === 0) {
        const floor = {
          name: "",
          description: "",
          heating: "1",
          heatingNote: "",
          files: [],
          sameCompositionAsFile: true,
          sameCompositionOnWall: false,
          compositions: [
            {
              name: "",
              materials: [
                {
                  type_id: 0,
                  type: "Interiérová omítka",
                  thickness: 15
                },
                {
                  type_id: "",
                  type: "",
                  thickness: 0
                },
                {
                  type_id: 0,
                  type: "Exteriérová omítka",
                  thickness: 15
                }
              ],
            }
          ]
        }
        var index = 0;
        for (let i = this.jsonData.floorsUnderGround - 1; i >= 0; i--) {
          this.jsonData.sectionsFloors.push(JSON.parse(JSON.stringify(floor)));
          this.jsonData.sectionsFloors[index].name = `${i + 1}. podzemní podlaží`;
          index++;
        }
        for (let i = 0; i < this.jsonData.floorsAboveGround; i++) {
          this.jsonData.sectionsFloors.push(JSON.parse(JSON.stringify(floor)));
          this.jsonData.sectionsFloors[index].name = `${i + 1}. nadzemní podlaží`;
          index++;
        }
      }
    },
    loadData () {
      if (this.slug && this.slug !== "") {
        this.loaded = false;
        this.$store.getters.api.get('/order/' + this.slug + '/warnings').then((response) => {
          this.warnings = response.data;
        });

        this.$store.getters.api.get("/order/" + this.slug).then((response) => {
          if (response.data.order.user) {
            this.modelData.first_name = response.data.order.user.first_name;
            this.modelData.last_name = response.data.order.user.last_name;
            this.modelData.email = response.data.order.user.email;
            this.modelData.phone = response.data.order.user.phone;
            if (response.data.order.user.id && this.$store.getters.user
              && response.data.order.user.id === this.$store.getters.user.id) {
                this.isMyOrder = true;
              }
          }
          this.modelData.company = response.data.order.company_name;
          this.modelData.companyId = response.data.order.company_id;
          this.modelData.companyVat = response.data.order.company_vat_id;
          this.modelData.terms = response.data.order.terms_consent;
          this.modelData.gdpr = response.data.order.gdpr_consent;
          this.requestInfo = response.data.order.request_info;
          // deep clone json data object
          this.jsonData = JSON.parse(JSON.stringify(response.data.order.data));

          if (this.modelData.company || this.modelData.companyId || this.modelData.companyVat) {
            this.modelData.invoicing = true;
          }
          this.energyLabel = response.data.order.energy_label
          this.isPaid = response.data.order.is_paid

          // start watching changes in form only after initial data are stored
          this.$nextTick(() => {
            this.loaded = true;
          });
        })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              alert(this.$t("error." + error.response.data.error_code ) + " (uuid " + this.slug + ") ");
            }
            else {
              alert(this.$t("error.undefined" + ": " + error));
            }
          });
      }
      this.$store.getters.api.get('/materials').then(function(response){
        this.materialOptions = response.data.materials;
        var name = this.$t("utils.other");
        this.materialOptions.push({
          id: -1,
          name: name,
          type: "",
          description: ""
        })
      }.bind(this));
    },
    validateData() {
      if (this.$refs.form.checkValidity() === false){
        this.$refs.form.reportValidity();
        return false;
      } else if (this.showForm === 3) {
        for (var i = 0; i < this.jsonData.sectionsFloors.length; i++){
          var item = this.jsonData.sectionsFloors[i];
          if (item.files && item.files.length === 0) {
            alert(this.$t("form.filesFloors", {name: item.name}));
            return false;
          }
        }
      } else if (this.showForm === 4) {
        if (this.jsonData.sectionsMain.some(item => item.files && item.files.length === 0)) {
          alert(this.$t("form.filesMain"));
          return false;
        }
      }
      else if (this.showForm === 5) {
        if (this.jsonData.sectionsWalls.some(item => item.files && item.files.length === 0 && item.fileCanBeUploaded)) {
          alert(this.$t("form.filesWalls"));
          return false;
        }
      }
      else if (this.showForm === 6) {
        if (this.jsonData.sectionsWindows.some((item, index) => index <= 1 && item.files && item.files.length === 0 && item.fileCanBeUploaded)) {
          alert(this.$t("form.filesWindows"));
          return false;
        }
      }
      return true;
    },
    redirectToForm(attempt, maxAttempts, length) {
      setTimeout(function () {
        if (this.$store.getters.isLoggedIn) {
          this.$router.push({ name: 'form', params: { uuid: this.slug } });
        }
        else {
          if (attempt >= maxAttempts) {
            alert(this.$t("error.longLogin"))
          }
          else {
            this.redirectToForm(attempt + 1, maxAttempts, length);
          }
        }
      }.bind(this), length);
    },
    saveData() {
      // first save to create the order
      if (this.updated && this.slug === "") {
        this.$store.getters.api.post("/order", {model_data: this.modelData, json_data: this.jsonData})
          .then((response) => {
            this.slug = response.data.order.slug;
            this.updated = false;
            this.modelData.addTechnician = false;
            // load user data after login and redirect to newly created order
            this.$store.commit("getUserProfile");
            // try 50 times every 100 if user is logged in to redirect (5 seconds)
            this.redirectToForm(0, 50, 100);
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              alert(this.$t("error." + error.response.data.error_code ));
            }
            else {
              alert(this.$t("error.undefined" + ": " + error));
            }
          });
      // update existing order
      } else if (this.updated && this.$store.getters.hasPerm("change_orders")) {
        this.$store.getters.api.put("/order/" + this.slug, {model_data: this.modelData, json_data: this.jsonData})
          .then((_response) => {
            this.updated = false;
            this.modelData.addTechnician = false;
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              alert(this.$t("error." + error.response.data.error_code ));
            }
            else {
              alert(this.$t("error.undefined" + ": " + error));
            }
          });
      }
    },
    sendReview() {
      this.$store.getters.api.put("/order-review/" + this.slug, {message: this.requestInfo})
        .then((_response) => {})
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code ));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });
    },
    sendForm() {
      this.modelData.addTechnician = true;
      this.updated = true;
      if (this.validateData() === true) {
        this.saveData();
      }
    },
    handleFileChange(event) {
        this.loading = true;
        let formData = new FormData();
        formData.append('attachment', event.target.files[0]);
        this.$store.getters.api.post("/order-file/" + this.slug, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function (response) {
          this.energyLabel = true;
          this.loading = false;

          // file has been successfully uploaded, clear the input
          event.target.value = null;
        }.bind(this))
    },
    downloadPhoto: function () {
      this.$store.getters.api.get('/order-file/' + this.slug,
        {
          responseType: 'blob'
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // Extract MIME type and infer file extension
          const mimeType = response.headers['content-type'];
          const extension = this.getExtensionFromMimeType(mimeType);

          // create the file name
          const filename = extension ? `${this.slug}.${extension}` : this.slug;

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    getExtensionFromMimeType(mimeType) {
      const mapping = {
        'application/pdf': 'pdf',
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'text/plain': 'txt',
        'application/zip': 'zip',
      };
      return mapping[mimeType] || '';
    }
  },
  created() {
    // check affiliate link and store it into cookies
    const affiliateLinkId = this.$route.query.af;

    if (affiliateLinkId) {
      // set the expiration to 2 weeks
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 14);
      const expires = `expires=${currentDate.toUTCString()}`;
      document.cookie = `af=${affiliateLinkId}; path=/; ${expires}`;
    }
  },
  mounted: function () {
    // change navbar based on permission
    this.$route.meta.navbar = this.$store.getters.hasPerm("view_navbar");
  },
  updated: function () {
    // change navbar based on permission
    this.$route.meta.navbar = this.$store.getters.hasPerm("view_navbar");
  },
};
</script>

<style lang="scss">
@import "@/scss/abstracts/_variables.scss";

$sectionNumber: 7;

.btn_min_size {
  min-width: 200px;
}
.circle {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 25px !important;
  border-color: #D9D9D9 !important;
  background: #D9D9D9 !important;
  color: #F2F2F2 !important;
  text-align: center;
  font-size: 32px !important;
  font-weight: 700;
  padding-top: 0px !important;
  z-index: 1000 !important;
}
/* Firefox-specific styles */
@-moz-document url-prefix() {
  .circle {
    color: #5A5A5A !important;
  }
}
.circle.active {
  background: #B7965E !important;
  border-color: #B7965E !important;
  color: #5A5A5A !important;
}
.page-item:has(.circle.active) ~ .page-item .circle {
  color: #5A5A5A !important;
}

#logo {
  max-height: 60px;
}

#line_height {
  width: 8px;
  height: calc(46px * ($sectionNumber + 1));
  flex-shrink: 0;
  background: #D9D9D9;
  transform: translate(-5px);
  -ms-transform: translate(-5px);
  -webkit-transform: translate(-5px);
  z-index: 999;
}

#line_width {
  width: calc(42px * ($sectionNumber + 1));
  height: 8px;
  flex-shrink: 0;
  background: #D9D9D9;
  transform: translateY(-25px);
  z-index: 999;
}

.form_part_tooltip {
  position: absolute;
  width: 150px;
  height: 50px;
  top: 0px;
  padding: 0px 10px;
  right: -170px;
  border-radius: 10px;
  border: 2px solid $primary;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D9D9D9;
  transition: 0.2s ease;
  text-align: center;
  color: #5A5A5A;
  font-weight: bold;
  font-size: 16px;
}

.page-link:hover ~ .form_part_tooltip {
  animation: smooth 3s ease;
}

@-webkit-keyframes smooth {
    0% { opacity: 0;}
    20% { opacity: 1;}
    80% { opacity: 1;}
    100% { opacity: 0;}
}

.hidden_link a{
  color: #A9A9A9;
}

@media only screen and (max-width: 768px){
  .pagination {
    background-color: #F2F2F2;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .top_offset {
    margin-top: 150px !important;
  }
  .form_part_tooltip {
    top: 70px;
    right: 0px;
  }
}
</style>