<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-circle" width="56" height="56" viewBox="0 0 24 24" stroke-width="2" stroke="red" fill="red" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" fill="white"/>
        <path d="M12 8v4"/>
        <path d="M12 16h.01" />
    </svg>
</template>

<script>
export default {}
</script>

<style></style>