<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4 class="m-2 mb-3">{{ $t("form.sectionPhotos") }}:</h4>
      <button v-if="$store.getters.hasPerm('download_files') && files.length > 0" type="button" class="btn btn-primary text-white mb-auto" @click="downloadZip()">
        {{ $t('utils.download') }}
      </button>
    </div>
    <template v-if="editable">
      <div class="row row-cols-1 g-3 mb-4">
        <div class="col position-relative" v-for="(file, index) in files" :key="index">
          <button type="button" class="preview" @click="downloadPhoto(file)">
            <div class="image_container" v-if="previewType(file) === 'image/jpeg' || previewType(file) === 'image/png'">
              <div class="image_overlay"></div>
              <img  class="preview" :src="getFileUrl(file)" />
            </div>
            <div v-else-if="previewType(file) === 'application/pdf'" class="formFile d-flex align-items-center justify-content-center pdf">PDF</div>
            <div v-else class="formFile d-flex align-items-center justify-content-center">?</div>
          </button>
          <Warning :size="45" v-if="reviews[file.slug]"/>
        </div>
        <label class="col">
          <input type="file" multiple accept=".pdf, image/jpeg, image/png" @change="handleFileChange($event)">
          <div
            class="formFile d-flex align-items-center justify-content-center"
            :style="{'aspect-ratio': files.length? '6/1': '2/1'}"
          >+</div>
        </label>
      </div>
    </template>
    <template v-else>
      <div class="row row-cols-1 g-3 mb-4 position-relative">
        <div class="col position-relative" v-for="(file, index) in files" :key="index">
          <button type="button" class="preview" @click="downloadPhoto(file)">
            <img v-if="previewType(file) === 'image/jpeg' || previewType(file) === 'image/png'" class="preview" :src="getFileUrl(file)" />
            <div v-else-if="previewType(file) === 'application/pdf'" class="formFile d-flex align-items-center justify-content-center pdf">PDF</div>
            <div v-else class="formFile d-flex align-items-center justify-content-center">?</div>
          </button>
          <Warning :size="60"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CONFIG } from "@/config.js";
import Icon from "@/components/Icon.vue";
import Warning from '@/components/Warning.vue';

export default {
  components: {
    Icon,
    Warning
  },
  emits: ['updatePreview', 'saveFileChanges'],
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    files: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      reviews: {}
    }
  },
  mounted: function () {
    this.reviewFiles();
  },
  methods: {
    previewType(file) {
      const parts = file.name.split('.');
      if (parts.length > 1 && ['jpg', 'jpeg'].includes(parts[parts.length - 1])) {
        return 'image/jpeg';
      }
      else if (parts.length > 1 && parts[parts.length - 1] === 'png') {
        return 'image/png';
      }
      else if (parts.length > 1 && parts[parts.length - 1] === 'pdf') {
        return 'application/pdf';
      }
      return null;
    },
    getFileUrl(file) {
      return `${CONFIG.api.baseUrl}/file/${file.slug}`;
    },
    handleFileChange(event) {
      const uploadPromises = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let formData = new FormData();
        formData.append('attachment', event.target.files[i]);
        formData.append('formPart', this.$store.getters.formPart);
        const uploadPromise = this.$store.getters.api.post('/order/' + this.$route.params.uuid + '/file', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function (response) {
          const fileObject = {
            name: response.data.name,
            slug: response.data.slug
          }
          this.files.push(fileObject);
        }.bind(this));
        uploadPromises.push(uploadPromise);
      }

      Promise.all(uploadPromises)
        .then(() => {
          // All files have been successfully uploaded, clear the input
          event.target.value = null;
          this.$emit('saveFileChanges');
        })
        .catch((error) => {
          console.error("An error occurred during file upload: ", error);
        });
    },
    downloadPhoto: function (file) {
      this.$store.getters.api.get('/file/' + file.slug,
        {
          responseType: 'blob'
        }).then((response) => {
          // set file url
          const fileUrl = URL.createObjectURL(response.data);
          this.$emit('updatePreview', file, fileUrl);
        });
    },
    reviewFiles: function () {
      for (var i = 0; i < this.files.length; i++){
        var slug = this.files[i].slug;
        this.$store.getters.api.get('/file/' + slug + "/info").then(function(response){
          this.reviews[slug] = response.data.to_review;
        }.bind(this));
      }
    },
    downloadZip: function () {
      // Construct the query string with multiple UUIDs
      let queryString = this.files.map(file => 'uuids=' + encodeURIComponent(file.slug)).join('&');

      this.$store.getters.api.get('/files?' + queryString, {
          responseType: 'blob'
        }).then((response) => {
          // set file url
          const fileUrl = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = "attachments.zip"; // Specifies the default filename.
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  }
}
</script>

<style scoped lang="scss">
.preview, .image_overlay {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  border-radius: 25px;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

label.col input[type="file"] {
  position: absolute;
  left: -1000px;
}
.formFile {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 2/1;
  min-height: 90px;
  border-radius: 25px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: #D9D9D9;
  font-size: 10rem;
  font-weight: 900;
  color: #888888;
  overflow: hidden;
}

.pdf {
  font-size: 5rem;
}

.formFile:hover, .image_overlay  {
  background: rgba(183, 150, 94, 0.3)
}

.image_container {
  position: relative;
  width:100%;
}

.image_overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.image_container:hover .image_overlay {
  opacity: 1;
}
</style>
