<template>
  <div>
    <div class="row p-3">
      <div class="col-12">
        <h2 class="pb-3 text-center">{{ $t("form.other") }}</h2>
        <p class="pb-3 text-center lead">{{ $t("form.otherSectionDescription1") }}</p>
        <Section :editable="editable" :sections="data.sectionsOther" :materialOptions="materialOptions" @updatePreview="updatePreview" @saveFileChanges="saveFileChanges" />
        <h3 class="pb-3 pt-5 text-center">{{ $t("form.otherSectionHeader1") }}</h3>
        <p class="pb-3 text-center">{{ $t("form.otherSectionText1") }}</p>
        <textarea v-if="editable" class="form-control" v-model="data.note" />
        <p v-else>{{ data.note }}</p>
        <div v-if="editable" class="d-flex">
          <button class="btn btn-lg btn-primary mx-auto mt-5 mb-3 text-white" @click="sendForm">
            {{ $t("form.saveAndSend") }}
          </button>
        </div>
      </div>
    </div>
    <PopupAttachment ref="attachmentModal" :header="header" :editable="$store.getters.hasPerm('review_orders')" :fileUrl="fileUrl" />
  </div>
</template>

<script>
import Section from "@/components/Section.vue"
import PopupAttachment from "@/components/PopupAttachment.vue";

export default {
  components: {
    Section,
    PopupAttachment
  },
  emits: ['sendForm', 'saveFileChanges'],
  props: {
    data: {
      type: Object,
      required: true
    },
    materialOptions: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      fileUrl: "#",
      header: ""
    }
  },
  methods: {
    sendForm() {
      this.$emit('sendForm');
    },
    updatePreview(fileObject, fileUrl) {
      this.fileUrl = fileUrl;
      this.header = fileObject.name;
      this.$refs.attachmentModal.openModal(fileObject.slug);
    },
    saveFileChanges() {
      this.$emit('saveFileChanges');
    }
  }
}
</script>

<style lang="scss">
</style>