// common configuration
const config = {
    api: {
        baseUrl: "https://www.rayhome.cz/api"
    },
    afterLoginRoute: "orders",
    vueAppUrl: "https://www.rayhome.cz"
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10010";
    config["api"]["baseUrl"] = "http://localhost:10011";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_michal") {
    config["vueAppUrl"] = "http://65.108.224.246:35101";
    config["api"]["baseUrl"] = "http://65.108.224.246:35100";
}

export const CONFIG = config;