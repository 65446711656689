<template>
    <div class="d-flex align-items-center justify-content-center thank_you">
        <div class="text-center mx-3">
            <img class="logo" src="/RayHome-logo-white.png" alt="RayHome Energy" />
            <h1 class="mt-5">{{ $t("thankYou.title") }}</h1>
            <h3>{{ $t("thankYou.description") }}</h3>
            <p class="mt-5">{{ $t("thankYou.contact") }}</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.thank_you {
    height: 100vh;
}
.logo {
    max-width: 300px;
    max-height: 200px;
}
</style>