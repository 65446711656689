<template>
  <div>
    <div class="row p-3">
      <div class="col-12 col-sm-5 pb-4 avatar">
        <img :src="userAvatarUrl" class="img-fluid rounded" :alt="$t('user.avatar')">
        <button class="btn btn-dark photo-icon" data-bs-toggle="modal" data-bs-target="#uploadPhoto">
          <icon icon="Edit" />
        </button>
      </div>
      <div class="col-12 col-sm-7">
        <div class="d-flex flex-wrap">
          <h1 class="me-auto">{{ userEdited.name }}</h1>
          <div>
            <button v-if="editable" class="btn btn-dark text-nowrap me-2" @click="editData(false)">
              {{ $t("utils.closeWithoutSaving") }}
            </button>
            <button class="btn btn-primary text-nowrap text-white" @click="editData(true)">
              <icon icon="Edit" />
              &nbsp;{{ editable ? $t('utils.saveChanges') : $t('utils.editDetails') }}
            </button>
          </div>
        </div>
        <h2 class="py-3">{{ $t("titles.personalDetails") }}</h2>
        <EditableRow v-if="editable" :header="$t('user.password')"
        :editable="editable"
        :inputType="'password'"
        :body="oldPassword"
        @body="(newValue) => {oldPassword = newValue}"
        />
        <EditableRow :header="$t('user.firstName')"
        :editable="editable"
        :body="userEdited.first_name"
        @body="(newValue) => {userEdited.first_name = newValue}"
        />
        <EditableRow :header="$t('user.lastName')"
        :editable="editable"
        :body="userEdited.last_name"
        @body="(newValue) => {userEdited.last_name = newValue}"
        />
        <EditableRow :header="$t('user.username')"
        :editable="editable"
        :body="userEdited.username"
        @body="(newValue) => {userEdited.username = newValue}"
        />
        <EditableRow :header="$t('user.email')"
        :editable="editable"
        :inputType="'email'"
        :body="userEdited.email"
        @body="(newValue) => {userEdited.email = newValue}"
        />
        <EditableRow :header="$t('user.phone')"
        :editable="editable"
        :body="userEdited.phone"
        @body="(newValue) => {userEdited.phone = newValue}"
        />
        <EditableRow v-if="editable" :header="$t('user.changePassword')" :editable="editable">
          <template v-slot:editor>
            <div class="d-flex flex-wrap pt-1">
              <input class="form-control my-1" type="password" v-model="newPassword" />
              <label class="form-label">{{ $t('user.confirmPassword') }}:</label>
              <input class="form-control mt-1" type="password" v-model="newPasswordRepeat" />
            </div>
          </template>
        </EditableRow>
        <h2 v-if="isTechnician" class="py-3">{{ $t("titles.orderAmount") }}</h2>
        <EditableRow v-if="isTechnician" :header="$t('user.maxOrders')"
        :editable="editable"
        :inputType="'number'"
        :body="userEdited.maximum_order_count"
        @body="(newValue) => {userEdited.maximum_order_count = newValue}"
        />
        <h2 class="pb-3">{{ $t("titles.functions") }}</h2>
        <ul>
          <li v-for="(func, i) in user.functions" v-key="`Func${i}`" class="functions">
            {{ $t("functions." + func.name) }}
          </li>
        </ul>
      </div>
    </div>
    <Popup :header="$t('user.uploadAvatar')" :editable="true" id="uploadPhoto" @save-data="uploadPhotoToServer">
      <template v-slot:editor>
        <input type="file" class="form-control" accept="image/*" @change="changePhoto">
      </template>
    </Popup>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"
import Popup from "@/components/Popup.vue";

export default {
  components: {
    EditableRow,
    Popup
  },
  data() {
    return {
      user: {},
      userEdited: {},
      editable: false,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      photo: null,
      hrefPhoto: null
    }
  },
  mounted: function () {
    this.user = this.$store.getters.user;
    this.userEdited = JSON.parse(JSON.stringify(this.user));
    if (this.user && this.user.avatar) {
      this.downloadPhoto();
    }
  },
  computed: {
    userAvatarUrl: function () {
      if (this.user && this.user.avatar && this.hrefPhoto) {
        return this.hrefPhoto;
      }
      return "https://via.placeholder.com/1000";
    },
    isTechnician: function () {
      if (this.user.functions) {
        for (const func of this.user.functions) {
          if (func.name === 'technical_specialist') return true;
        }
      }
      return false;
    }
  },
  methods: {
    editData(save) {
      if (this.editable && save === true) {
        if (!this.oldPassword) {
          alert(this.$t("user.enterCurrentPassword"));
          return;
        } else if (this.oldPassword && this.newPassword && this.newPassword !== this.newPasswordRepeat) {
          alert(this.$t("user.newPasswordsNotMatch"));
          return;
        }
        this.userEdited.name = this.userEdited.first_name + " " + this.userEdited.last_name;
        const editedData = {
          ...this.userEdited,
          old_password: this.oldPassword,
          new_password: this.newPassword
        }
        this.$store.getters.api.put("/auth/user", editedData)
          .then((response) => {
            this.$store.commit("saveUserProfile", response.data.user);
            this.editable = false;
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              alert(this.$t("error." + error.response.data.error_code ));
            }
            else {
              alert(this.$t("error.undefined" + ": " + error));
            }
          });
      } else if (this.editable) {
        this.editable = false;
        this.userEdited = JSON.parse(JSON.stringify(this.user));
      } else {
        this.editable = true;
      }
    },
    changePhoto(event) {
      this.photo = event.target.files[0];
    },
    uploadPhotoToServer: function () {
      let formData = new FormData();
      formData.append('avatar', this.photo);
      this.$store.getters.api.post('/auth/avatar',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        this.user.avatar = true;
        this.downloadPhoto();
      }.bind(this));
    },
    downloadPhoto: function () {
      this.$store.getters.api.get('/auth/avatar',
        {
          responseType: 'blob'
        }).then((response) => {
          this.hrefPhoto = URL.createObjectURL(response.data);
        });
    },
  }
}
</script>

<style lang="scss">

.avatar {
  position: relative;
}

.avatar img {
  width: 100%;
}

.photo-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-32%, 10%);
  -ms-transform: translate(-32%, 10%);
  -webkit-transform: translate(-32%, 10%);
  opacity: 0.4;
}

.photo-icon:hover {
  opacity: 1;
}

.functions {
  font-size: 1.3rem;
}
</style>