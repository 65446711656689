<template>
    <div class="row justify-content-between pe-5 ps-4">
      <div class="col-12 col-md-2 col-xl-1">
        <select class="form-select" :value="perPage" @change="updatePerPage($event.target.value)">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="col-12 col-md-10 col-xl-8 my-2 my-md-0">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click="goToPage(1)">
              <icon icon="PlayerFirst" />
            </a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click="goToPage(currentPage - 1)">
              <icon icon="PlayerPrev" />
            </a>
          </li>
          <li v-for="i in totalPages" :key="i" :class="{ active: i === currentPage }">
            <a v-if="currentPage < 5 && i <= 9" class="page-link" href="#" @click="goToPage(i)">{{ i }}</a>
            <a v-else-if="currentPage > totalPages - 5 && i > totalPages - 9" class="page-link" href="#" @click="goToPage(i)">{{ i }}</a>
            <a v-else-if="i > currentPage - 5 && i < currentPage + 5" class="page-link" href="#" @click="goToPage(i)">{{ i }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click="goToPage(currentPage + 1)">
              <icon icon="PlayerNext" />
            </a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click="goToPage(totalPages)">
              <icon icon="PlayerLast" />
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-xl-3 text-end">
        <span>Celkový počet záznamů: {{ totalCount }}</span>
      </div>
    </div>
  </template>


  <script>
  export default {
    props: {
      totalCount: {
        type: Number,
        required: true
      },
      perPage: {
        type: Number,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      }
    },
    computed: {
      totalPages: function () {
        return Math.ceil(this.totalCount / this.perPage);
      }
    },
    methods: {
      goToPage(pageNumber) {
        this.$emit('page-changed', pageNumber);
      },
      updatePerPage(newPerPage) {
      this.$emit('update:per-page', parseInt(newPerPage));
      }
    }
  }
  </script>


  <style scoped lang="scss">
  @import "@/scss/abstracts/_variables.scss";

    .pagination {
      --bs-pagination-padding-x: 0.75rem;
      --bs-pagination-padding-y: 0.375rem;
      --bs-pagination-font-size: 1rem;
    //   --bs-pagination-color: #111;
    //   --bs-pagination-bg: #fff;
      --bs-pagination-border-width: 1px;
    //   --bs-pagination-border-color: #dee2e6;
      --bs-pagination-border-radius: 0.375rem;
    //   --bs-pagination-hover-color: #0fb29d;
    //   --bs-pagination-hover-bg: #e9ecef;
    //   --bs-pagination-hover-border-color: #0fb29d;
      --bs-pagination-focus-color: var(--bs-link-hover-color);
    //   --bs-pagination-focus-bg: #e9ecef;
    //   --bs-pagination-focus-box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25);
    //   --bs-pagination-active-color: #fff;
    //   --bs-pagination-active-bg: #0fb29d;
    //   --bs-pagination-active-border-color: #0fb29d;
    //   --bs-pagination-disabled-color: #6c757d;
    //   --bs-pagination-disabled-bg: #fff;
    //   --bs-pagination-disabled-border-color: #dee2e6;
      display: flex;
      padding-left: 0;
      list-style: none;
    }
  </style>