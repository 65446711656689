<template>
  <div class="container-fluid">
    <div v-if="this.$store.getters.hasPerm('view_navbar')">
      <NavbarHorizontal/>
      <router-view class="navbar_height_margin"/>
    </div>
    <!-- <div v-if="this.$store.getters.hasPerm('view_navbar')">
      <NavbarVertical/>
      <div>
        <router-view/>
      </div>
    </div> -->
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
import NavbarHorizontal from "@/components/NavbarHorizontal.vue"
import NavbarVertical from "@/components/NavbarVertical.vue"

export default {
  components: {
    NavbarHorizontal,
    NavbarVertical
  },
  data: function () {
    return {
      sNavbar: false
    }
  },
  computed: {
    pageTitle: function () {
      const title = this.$route.meta.title;
      let title_tranlation = null;

      if (title != null) {
        title_tranlation = this.$t(title);
        document.title = "RayHome Energy - " + this.$t(title);
      }

      return title_tranlation;
    },
    showNavbar: function () {
      if (this.sNavbar != null) {
        return this.sNavbar;
      }
      return true;
    }
  },
  watch: {
    // lookout for changes
    pageTitle: function () { },
    "$route.meta.navbar": {
      handler: function (val) {
        setTimeout(function () {
          this.sNavbar = this.$route.meta.navbar;
        }.bind(this), 50)
      },
      deep: true,
      immediate: false
    },
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>